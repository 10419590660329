function QuantityInput(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({
    }, config);

    this.quantityInput = $(obj);
    this.input = this.quantityInput.find('input');
  	this.buttons = this.quantityInput.find('button');
    this.maximum = (this.quantityInput.data('maximum') !== undefined) ? parseInt(this.quantityInput.data('maximum')) : null;
    this.min = (this.quantityInput.data('minimum') !== undefined) ? parseInt(this.quantityInput.data('minimum')) : 0;
    this.init();
}

QuantityInput.prototype = {
    init: function() {
    	this.validateNumber(this.input.val());
        this.bindEvents();
    },
    bindEvents: function() {
    	var self = this;
        self.buttons.on('click',$.proxy(this.changeInputValue, self));
        self.input.on('keydown', $.proxy(this.preventAlphaInput, self));
    },
    changeInputValue: function(e) {
    	var value = parseInt(this.input.val());
    	var operation = parseInt($(e.target).data('value'));

        if (isNaN(parseInt(value))) {
            var result = this.min;
        } else {
            var result = value + operation;
        }


		this.input.val(result);
		this.input.attr('value', result);
		this.validateNumber(result);

		$.event.trigger('onQuantityUpdate', [$(e.target), result]);

        $('#liveregion').html(result);

    	e.preventDefault();
    },
    preventAlphaInput: function(e){
    	if ((e.shiftKey && e.keyCode != 9) || e.ctrlKey || e.altKey) {
            e.preventDefault();
        } else {
            var n = e.keyCode;
            if (!((n == 8)                  // backspace
                || (n == 9)                 // tab
                || (n == 46)                // delete
                || (n >= 35 && n <= 40)     // arrow keys/home/end
                || (n >= 48 && n <= 57)     // numbers on keyboard
                || (n >= 96 && n <= 105))   // number on keypad
                ) {
                e.preventDefault();
                // Prevent character input
            }
        }
    },
    validateNumber: function(result) {
    	var isWitinLimits = false;
		this.buttons.removeAttr('disabled');
        this.buttons.removeAttr('aria-disabled');

		if(this.maximum != null && this.maximum != '0') {
			if(result >= this.maximum) {
	    		this.quantityInput.find('.is-up').attr('disabled', true).attr('aria-disabled', 'true');
	    		isWitinLimits = true;
            } else if(result < this.maximum && result > this.min) {
	    		isWitinLimits = true;
            } else if(result <= this.min ) {
	    		this.quantityInput.find('.is-down').attr('disabled', true).attr('aria-disabled', 'true');
	    		isWitinLimits = true;
	    	}
		} else {
			if(result > this.min ) {
	    		isWitinLimits = true;
			} else if(result <= this.min ){
				this.quantityInput.find('.is-down').attr('disabled', true).attr('aria-disabled', 'true');
			}
		}

    	return isWitinLimits;
    }
};

VTT.extend.Components(QuantityInput, 'QuantityInput');
