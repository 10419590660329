function Tab(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.enabledClose = false;

    var lang = $('html').attr('lang');

    this.config = $.extend({
        defaultOpenedTab: 1,
        closeOnClick: false,
        ariaTextClass: 'aria-text',
        tabWrapperClass: 'tab-wrapper',
        tabTriggerClass: 'tab-trigger',
        tabContentClass: 'tab-content',
        ariaTextInactive: (lang === 'en') ? 'Click to show this tab':'Cliquer pour afficher cet onglet',
        ariaTextActive: (lang === 'en') ? 'Tab currently displayed':'Onglet présentement affiché',
        ariaTextTabOpened: (lang === 'en') ? 'Click to close folder':'Cliquer pour fermer ce tiroir',
        ariaTextTabClosed: (lang === 'en') ? 'Click to open folder':'Cliquer pour ouvrir ce tiroir',
        easing: 'swing',
        duration: 400,
        onFocus: $.noop,
        beforeOpen: $.noop,
        afterOpen: $.noop,
        beforeClose: $.noop,
        afterClose: $.noop,
        onBlur: $.noop
    }, config);

    this.tab = obj;

    // Get the tabs wrapper
    this.tabWrapper = this.tab.find('.' + this.config.tabWrapperClass);

    // Get the tab trigger and transform the tags in a <button> tag
    this.tab.find('.' + this.config.tabTriggerClass).buttonize({
        a11y: this.config.a11y
    });

    this.tabTrigger = this.tab.find('.' + this.config.tabTriggerClass);

    // Get the tab content
    this.tabContent = this.tab.find('.' + this.config.tabContentClass);

    // Create and get the aria text
    this.tabTrigger.append('<span class="' + this.config.ariaTextClass + ' visuallyhidden">' + this.config.ariaText + '</span>');
    this.tabTriggerAriaText = this.tabTrigger.find('.' + this.config.ariaTextClass);

    // Set AriaText
    this.setAriaText();

    // Hide all tabs content
    this.tabContent.hide();

    this.init();

}

Tab.prototype = {
    // Component initialization
    init: function() {
        var self = this;
        $(window).on('load', function(){
            self.adjustWidth();

            // Initialize tabTrigger positions
            self.adjustTabTrigger();

            // Initialize wrapper layout
            self.setWrapperLayout();

            // Bind events
            self.bindEvents();
            self.bindResize();

            self.changeTab(self.config.defaultOpenedTab - 1);
        });
    },

    // Bind events with actions
    bindEvents: function() {
        // Click events
        this.tabTrigger.on('click', $.proxy(function(e) {
            var element = e.currentTarget;
            if (!$(element).parents('.' + this.config.tabWrapperClass).hasClass(this.classes.active)) {
                this.changeTab($(element).parents('.' + this.config.tabWrapperClass).index());
            } else if (this.config.closeOnClick || this.enabledClose === true)  {
                this.tabContent.hide();
                $(element).parents('.' + this.config.tabWrapperClass).removeClass(this.classes.active);
            }
            this.setAriaText();
            $('#liveregion').text('Onglet affiché')
        }, this));
        // Focus events
        this.tabTrigger.on('focus', this.config.onFocus);
        this.tabTrigger.on('blur', this.config.onBlur);
    },
    bindResize: function() {
        $(window).on('resize', $.proxy(function() {
            this.adjustTabTrigger();
            this.setWrapperLayout();

            this.setAriaText();

        }, this));
    },
    setWrapperLayout: function() {
        this.tab.css({
            position: 'relative',
            paddingTop: $(this.calculateHighestTabTrigger()).eminize()
        });
    },
    adjustWidth: function() {
        var elementTab = this.tab.children().length;
        switch(elementTab) {
            case 3:
                this.tab.css('max-width', '840px');
                break;
            case 4:
                this.tab.css('max-width', '1040px');
                break;
            case 5:
                this.tab.css('max-width', '1340px');
                break;
            default :
                this.tab.css('max-width', '780px');
                break;
        }
    },
    // Initialize tabTrigger positions
    adjustTabTrigger: function() {
        this.tabTrigger.each($.proxy(function(index, el) {
            $(el).css({
                position: 'absolute',
                top: '0',
                left: this.calculateLeftOffset(index).toString(),
            });
        }, this));
    },

    // Function to change active tab
    changeTab: function(index) {
        this.tabContent.hide();
        this.tabWrapper.eq(index).find('.' + this.config.tabContentClass).show();
        this.tabWrapper.removeClass(this.classes.active).eq(index).addClass(this.classes.active);
        this.tabTrigger.removeClass(this.classes.active).eq(index).addClass(this.classes.active);
    },

    // Function to calculate the height of the highest tab trigger
    calculateHighestTabTrigger: function() {
        var height = 0;

        this.tabTrigger.each(function(index, el) {
            if ($(el).outerHeight() > height) {
                height = $(el).outerHeight();
            }
        });

        return height;
    },
    // Close all tabs
    closeAll: function() {
        this.tabContent.hide();
        this.tabContent.parents('.' + this.config.tabWrapperClass).removeClass(this.classes.active);
        this.setAriaText();
    },
    enableCloseClick: function() {
        this.enabledClose = true;
    },
    disableCloseClick: function() {
        this.enabledClose = false;
    },
    calculateLeftOffset: function(index) {
        var offset = 0;
        for (var i = 0; i < index; i++) {
            offset += this.tabTrigger.eq(i).outerWidth() + 8;
        }
        return $(offset).eminize();
    },
    setAriaText: function() {
        var self = this;
        if ($(window).width() < 1175) {

            this.tabTriggerAriaText.text(this.config.ariaTextTabClosed);
            this.tabTriggerAriaText.each(function(index, element){
                if ($(element).parents('.' + self.config.tabWrapperClass).hasClass(self.classes.active)) {
                    $(element).text(self.config.ariaTextTabOpened);
                }
            });
        } else {

            this.tabTriggerAriaText.text(this.config.ariaTextInactive);
            this.tabTriggerAriaText.each(function(index, element){
                if ($(element).parents('.' + self.config.tabWrapperClass).hasClass(self.classes.active)) {
                    $(element).text(self.config.ariaTextActive);
                }
            });
        }
    },
};
VTT.extend.Components(Tab, 'Tab');
