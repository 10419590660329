function TableController(obj, config, app) {
	this.app = app;
    this.classes = this.app.Classes;
    this.origTable = obj;
    this.mobileTable = $('<table class="is-mobile-table force-mobile is-hidden" />');
    this.origHeaders = null;
    this.origContent = null;
    this.labelContent = {
    	'en': 'This table scroll horizontaly',
    	'fr': 'Ce tableau peut être glissé sur le côté'
    }

    this.init();
}
TableController.prototype = {
	init: function() {
		this.wrapTable();
	},
	bindEvents: function() {
		var self = this;
		$(window).on('resize', $.proxy(self.toggleVisibility, self));
	},
	drawMobileTable: function() {
		var self = this;
		if(!this.origTable.hasClass('is-simple')) {
			var singleHeaderAdded = false;
			var rows = this.origContent.find('tr');
			var tableClass = self.origTable.attr('class').replace('js-controlled-table', '');
			_.each(rows, function(row) {
				var rowHeaderClone = $(row).find('th').clone().removeAttr('id');
				var darkHeadersClone = self.origHeaders.clone().removeAttr('id');
				var rowCellsClone = $(row).find('td').clone().removeAttr('headers');
				var mobileRowHeader = $('<tr />').append(rowHeaderClone);
				var mobileRowDarkHeaders = $('<tr />').append(darkHeadersClone);
				var mobileRowContent = $('<tr />').append(rowCellsClone);

				if( self.origHeaders.length > 1 ) {
					rowHeaderClone.prop('colspan', darkHeadersClone.length);
					mobileRowDarkHeaders.addClass('dark-header-container is-smaller');
					self.mobileTable.append(mobileRowHeader, mobileRowDarkHeaders, mobileRowContent);
				} else {
					if(!singleHeaderAdded) {
						mobileRowDarkHeaders.find('th').addClass('is-bigger');
						self.mobileTable.append(mobileRowDarkHeaders);
						singleHeaderAdded = true;
					}
					self.mobileTable.append(mobileRowHeader, mobileRowContent);
				}
			});
		} else {
			var headers = this.origTable.find('th');
			var cells = this.origTable.find('td');
			_.each(headers, function(header, key) {
				var newRowH = $('<tr />');
				var newRowD = $('<tr />');
				newRowH.append($(header).clone());
				newRowD.append($(cells[key]).clone());
				self.mobileTable.append(newRowH, newRowD);
			});
		}

		self.origTable.after(self.mobileTable);
		self.mobileTable.addClass(tableClass);
		self.addAccessibilityToMobile();
	},
	addAccessibilityToMobile: function() {
		var self = this;
		var mobileRows = this.mobileTable.find('tr');


		mobileRows.each(function(mri, row){
			var headers = $(row).find('th');
			var cells = $(row).find('td');
			if(!self.origTable.hasClass('is-simple')) {
				headers.each(function(hi, header){
					$(header).attr('id', 'mc' + (hi + 1) + 'l' + (mri + 1));
				});
				cells.each(function(ci, cell) {
					if( self.origHeaders.length > 1 ) {
						$(cell).attr('headers', 'mc1l' + (mri - 1) + ' mc' + (ci + 1) + 'l' + (mri));
					} else {
						$(cell).attr('headers', 'mc1l1 mc' + (ci + 1) + 'l' + (mri));
					}
				});
			} else {
				headers.each(function(hi, header){
					$(header).attr('id', 'mc' + (hi + 1) + 'l' + (mri + 1));
				});
				cells.each(function(ci, cell) {
					$(cell).attr('headers', 'mc' + (ci + 1) + 'l' + mri);
				});
			}
		});
	},
	toggleVisibility: function() {
		var desktop = this.origTable.is(':visible');
		var mobile = this.mobileTable.is(':visible');

		if(!mobile && this.origTable.outerWidth() <= 535) {
			this.origTable.addClass('is-hidden');
			this.mobileTable.removeClass('is-hidden');
		} else if(!desktop && this.mobileTable.outerWidth() > 535 ) {
			this.mobileTable.addClass('is-hidden');
			this.origTable.removeClass('is-hidden');
		}
	},
	wrapTable: function() {
		this.origTable.wrap('<div class="table-wrapper"></div>').before('<span class="notice">' + this.labelContent[$('html').attr('lang')] + '</span>');
	}
}
VTT.extend.Components(TableController, 'TableController');
