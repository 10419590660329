function SelectBox(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.config = $.extend({}, {
        triggerClass    : 'js-menu-trigger',
        titleClass      : 'js-menu-title',
        menuType        : 'header',
        makeButton      : true,
        a11y            : true,
        ariaTextOpen    : 'Cliquer pour ouvrir le menu et accéder aux autres sections',
        ariaTextClose   : 'Cliquer pour fermer le menu'
    }, config);

    this.menu = obj;
    
    this.menuTitle = this.menu.find('.' + this.config.titleClass);
    this.subMenu = this.menu.find('.' + this.config.triggerClass);

    if (this.subMenu.length === 0) return;

    this.menuButton = this.menu.find('.' + this.subMenu.buttonize({ a11y: this.config.a11y, a11yText: this.config.ariaTextOpen }).attr('class').split(' ')[0]);

    this.init();
}

SelectBox.prototype = {
    init: function() {
        this.bindButtonEvent();
        this.bindHtmlEvent();
        this.sanitizeHtml(this.menuTitle);
        $.each(this.menu.find('li'), $.proxy(function(key, el) { this.sanitizeHtml($(el)); }, this));
    },
    bindButtonEvent: function() {
        this.menu.on('click','button' ,$.proxy(function(e) {
            this.toggleOpenClass();
            this.toggleAriaText(this.menuButton);
            e.stopPropagation();
        }, this));
    },
    bindHtmlEvent: function() {
        $('html').on('click', $.proxy(function() {
            if (this.menu.hasClass(this.classes.open)) this.menu.removeClass(this.classes.open); 
        }, this));
    },
    sanitizeHtml: function($el) {
        if ($el.html().indexOf('<br>', 0) !== -1)  $el.html($el.html().replace('<br>', ' '));
    },
    toggleOpenClass: function() {
        if (this.menu.hasClass(this.classes.open)) {
            this.menu.removeClass(this.classes.open);
        } else {
            this.menu.addClass(this.classes.open);
        }
    },
    toggleAriaText: function($btn) {
        var $ariaContent = $btn.find('span.aria-holder');
        $.each($btn, $.proxy(function() {
            if (this.menu.hasClass(this.classes.open)) {
                $ariaContent.html(this.config.ariaTextClose);
            } else {
                $ariaContent.html(this.config.ariaTextOpen);
            }
        }, this));
    }
};

VTT.extend.Components(SelectBox, 'SelectBox');