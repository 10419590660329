

function LoadingList(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.listContainer = $(obj);
    this.list = this.listContainer.find('.js-list');
    this.groups = this.list.find('.js-group');
    this.items = this.list.find('.js-item');
    this.byPage = this.listContainer.data('bypage');
    this.buttonLabel = this.listContainer.data('buttonlabel');
    this.a11yLoadingText = this.listContainer.data('a11yloadedtext');

    this.init();
}
LoadingList.prototype = {
    a11yContainer: null,
    moreButton: null,
    page: null,
    limit: 10,
    init: function() {
        this.addAccessibilityContainer();
        this.setPage();
        this.createButton();
        this.hideOutOfBound();
        this.bindEvents();
    },
    bindEvents: function() {
        this.moreButton.on('click', $.proxy(this.loadNextPage,this));
    },
    addAccessibilityContainer: function(){
        this.a11yContainer = $('<span class="visuallyhidden" aria-hidden="true" aria-live="polite" aria-atomic="true"></span>');

        //Ajout du message d'accessibilite
        this.listContainer.append(this.a11yContainer);
    },
    hideOutOfBound: function(){
        this.items.filter(':gt('+ (this.limit -1 )+')').hide();
        this.updateGroups();
        if(this.items.length > this.limit){
            this.list.after(this.moreButton);
        }
    },
    updateGroups: function(){
        this.groups.each(function(i, group){
            var $group = $(group);
            $group.show();
            var visibleItems = $group.find('.js-item:visible').length;
            if(visibleItems == 0){
                $group.hide();
            }
        })
    },
    loadNextPage: function() {
        this.page++;
        this.limit = this.page * this.byPage;
        window.history.replaceState(null,null, QS(window.location.href).set('page', this.page).url);


        this.items.filter(':lt('+ (this.limit )+')').show();
        this.updateGroups();
        if(this.items.length <= this.limit){
            this.moreButton.remove();
        }

        this.a11yContainer.text(this.a11yLoadingText);

    },
    setPage: function(){
        this.page = parseInt(QS().get('page'));
        if( typeof this.page == 'undefined' || isNaN(this.page) || this.page < 1){
            this.page = 1;
        }
        this.limit = this.page * this.byPage;
    },
    createButton: function(){
        this.moreButton = $('<button class="btn is-dark-teal is-centered">'+this.buttonLabel+'</button>');
    }
};

VTT.extend.Components(LoadingList, 'LoadingList');
