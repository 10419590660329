function Tooltip(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.tooltip = obj;
    this.config = $.extend({}, {
        tooltipTrigger: 'tooltip-trigger',
        tooltipContent: 'tooltip-content',
        a11y: true,
        a11yText: 'Cliquer pour ouvrir la boîte d\'information supplémentaire'
    }, config, this.tooltip.data());

    this.tootltipContent = this.tooltip.find('*[data-js="' + this.config.tooltipContent + '"]');

    this.init();
}

Tooltip.prototype = {
    init: function() {

        this.setToolTipTrigger();

        if ($('body').attr('data-environment') !== 'mobile') {
            this.bindMouseEvents();
            this.bindKeyDown();
            this.bindFocus();
        } else {
            this.bindMouseEvents();
            this.bindKeyDown();
            this.bindTap();
            this.bindTapOut();
        }
        this.bindScroll();
    },
    setToolTipTrigger: function() {
        if ($('html').hasClass('lt-ie9') || $('body').hasClass('lt-ie9')) {
            this.tootltipTrigger = this.tooltip.find('*[data-js="' + this.tooltip.find('*[data-js="' + this.config.tooltipTrigger + '"]').buttonize({
                a11y: this.config.a11y,
                a11yText: this.config.a11yText
            }).attr('data-js') + '"]');
        } else {
            this.tootltipTrigger = this.tooltip.find('*[data-js="' + this.tooltip.find('*[data-js="' + this.config.tooltipTrigger + '"]').buttonize({
                a11y: this.config.a11y,
                a11yText: this.config.a11yText
            }).attr('data-js') + '"]').prop('type', 'button');
        }
    },
    bindMouseEvents: function() {
        this.tooltip.on({
            mouseenter: $.proxy(function() {
                this.showTip(false);
            }, this),
            mouseleave: $.proxy(function() {
                this.hideTip(false);
            }, this)
        });
    },
    bindKeyDown: function() {
        this.tooltip.on('click keydown', 'button', $.proxy(function(e) {

            var keyCode = e.keyCode;

            if (keyCode === 13 || e.type === 'click') {
                this.tootltipContent.attr('tabindex', '-1');
                this.showTip(false);
                this.tootltipContent.focus();

                if ($(this.tooltip).closest('form').length > 0) {
                    e.preventDefault();
                }
            }
        }, this));
    },
    bindTap: function() {
        this.tooltip.on('tap', 'button', $.proxy(function(e) {
            this.hideAll(true);
            this.manageClick();
            e.preventDefault();
        }, this));
    },
    bindTapOut: function() {
        $('html').on('tap', $.proxy(function(e) {
            if (!$(e.target).hasClass(this.config.tooltipTrigger)) {
                if (!$(e.target).parent().hasClass(this.config.tooltipTrigger)) {
                    this.hideAll(true);
                }
            }
        }, this));
    },
    bindFocus: function() {
        this.tootltipContent.on('focusout', $.proxy(function() {
            this.hideTip();
        }, this));
    },
    bindScroll: function() {
        var previousScrollY = window.scrollY;

        window.addEventListener('scroll', $.proxy(function() {
            var currentScrollY = window.scrollY;
            if (Math.abs(currentScrollY - previousScrollY) > 10) {
                this.hideTip();
            }
            previousScrollY = currentScrollY;
        }, this));
    },
    manageClick: function() {
        if (this.tooltip.hasClass(this.classes.open)) {
            this.hideTip(true);
        } else {
            this.showTip(true);
        }
    },
    showTip: function(isMobile) {
        var triggerRect = this.tootltipTrigger[0].getBoundingClientRect();
        var viewportWidth = window.innerWidth;
        var viewportHeight = window.innerHeight;
        var withOffset = this.tooltip.offset().left <= 200 ? false : true;

        $('#liveregion').text(SAAQ.Helpers.translate("open_tooltip"));

        if (!isMobile) {
            this.tootltipContent.css({
                'top': (triggerRect.bottom - this.tooltip.height() - 8) + 'px',
                'left': triggerRect.left + 'px',
                'position': 'fixed',
                'bottom': 'initial',
            });
            // Check for overflow and adjust position if necessary
            var tooltipRect = this.tootltipContent[0].getBoundingClientRect();
            if (tooltipRect.right > viewportWidth) {
                // Adjust left position to prevent overflow on the right
                this.tootltipContent.css('left', (viewportWidth - tooltipRect.width - 8) + 'px');
            }
            if (tooltipRect.bottom > viewportHeight) {
                // Adjust top position to prevent overflow on the bottom
                this.tootltipContent.css('top', (viewportHeight - tooltipRect.height - 8) + 'px');
            }
            if (tooltipRect.left < 0) {
                // Adjust left position to prevent overflow on the left
                this.tootltipContent.css('left', '8px');
            }
            if (tooltipRect.top < 0) {
                // Adjust top position to prevent overflow on the top
                this.tootltipContent.css('top', '8px');
            }

            if (this.tooltip.parents('.contenuContextuel').length && this.tooltip.parents('.processStep').length) {
                this.tootltipContent.css({
                    'margin-left' : -this.tooltip.position().left
                });
            }
            this.tootltipContent.fadeIn(100);
            this.manageViewPortOffset(withOffset);      
        } else {
            this.tootltipContent.css({
                'position': 'fixed',
                'bottom': '0',
                'left': '0',
                'top': 'initial',
                'width': '100vw'
            }).addClass('has-x').slideDown();
        }
        this.tooltip.addClass(this.classes.open);
    },
    hideTip: function(isMobile) {

        $('#liveregion').text(SAAQ.Helpers.translate("close_tooltip"));

        if (!isMobile) {
            this.tootltipContent.fadeOut(250);
        } else {
            this.tootltipContent.removeClass('has-x');
            this.tootltipContent.slideUp();
        }
        this.tooltip.removeClass(this.classes.open);
    },
    hideAll: function(isMobile) {
        var self = this;
        if (!isMobile) {
            $('.tooltip.' + self.classes.open).each(function() {
                var $this = $(this);
                $this.find('.' + self.config.tooltipContent).fadeOut(250);
                $this.removeClass(self.classes.open);
            });
        } else {
            $('.tooltip.' + self.classes.open).each(function() {
                var $this = $(this);
                $this.find('.' + self.config.tooltipContent).slideUp(400, function() {
                    $this.removeClass(self.classes.open);
                });
            });
        }
    },
    manageViewPortOffset: function(withOffset) {
        if (withOffset) {
            this.tootltipContent.css('right', '-32px');
        } else {
            this.tootltipContent.css('right', -(240 - this.tooltip.offset().left));
        }
    }
};

VTT.extend.Components(Tooltip, 'Tooltip');
