

function BackNews(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.backLink = $(obj);

    this.init();
}

BackNews.prototype = {
    init: function() {
        var href = this.backLink.attr('href');
        if(href.substring(0,4) !== 'http'){
            href = window.location.origin + '/' + href;
        }
        if(document.referrer.indexOf(href) == 0){
            this.backLink.attr('href', document.referrer);
        }
    }
};

VTT.extend.Components(BackNews, 'BackNews');
