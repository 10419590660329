// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: document.documentElement.getAttribute('lang'),
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false
    force_consent: false,
    cookie_expiration: 365,                     // default: 182 (days)

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
    },

    languages: {
        'fr': {
            consent_modal: {
                title: 'En toute sécurité, sur la route comme sur le Web',
                description: 'Nous avons à cœur la protection de vos renseignements personnels et de votre vie privée. C’est pourquoi nos pratiques en matière de collecte de données sont régies par une <a href="/politiques/confidentialite">Politique de confidentialité</a> responsable et transparente. En poursuivant votre navigation sans modifier vos préférences, vous acceptez l’utilisation des témoins.',
                primary_btn: {
                    text: 'Autoriser tous les fichiers témoins',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Refuser',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Préférences des fichiers témoins',
                save_settings_btn: 'Enregistrer',
                accept_all_btn: 'Tout accepter',
                reject_all_btn: 'Tout rejeter',
                close_btn_label: 'Fermer',
                cookie_table_headers: [
                    {col1: 'Nom'},
                    {col2: 'Description'},
                ],
                blocks: [
                    {
                        title: '',
                        description: 'Vous pouvez spécifier vos paramètres ci-dessous. Ceux-ci seront conservés pendant une période de 365 jours. Des informations détaillées sur la manière dont vous pouvez retirer votre consentement à tout moment peuvent être trouvées dans notre <a href="/politiques/confidentialite">Politique de confidentialité</a>.',
                    }, {
                        title: 'Témoins fonctionnels obligatoires',
                        description: '',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            {
                                col1: 'Préférences de fichiers témoins',
                                col2: 'Permet de conserver vos préférences en matière de collecte de données.',
                            },{
                                col1: 'Session utilisateur',
                                col2: 'Permet de conserver certaines actions effectuées lors de la navigation tels que la langue, les filtres ainsi que d\'utiliser nos formulaires.',
                            },{
                                col1: 'Google ReCaptcha',
                                col2: 'Permet de valider que vous pouvez utiliser les formulaires protégés avec un recaptcha.',
                            }
                        ]
                    }, {
                        title: 'Performance et analyse',
                        description: '',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'Google Analytics',
                                col2: 'Permet d’obtenir des informations concernant le nombre d’utilisateurs présents sur le site et leur utilisation, tels que le nombre de pages vues, le taux de rebond, la durée moyenne d’une visite, l\'origine du trafic, etc.',
                            }
                        ]
                    }, {
                        title: '',
                        description: 'Vous pouvez retirer votre consentement à tout moment, individuellement ou dans son intégralité. Toutefois, les données qui ont été légalement collectées jusqu\'à ce retrait peuvent toujours être traitées par le fournisseur.',
                    }
                ]
            }
        },
        'en': {
            consent_modal: {
                title: 'Safety first, on the road and online',
                description: 'We are committed to protecting your personal information and privacy. That’s why, when we collect data, we follow a <a href="/en/policies/privacy">Policy on Privacy</a> that is responsible and transparent. By continuing to browse without modifying your preferences, you accept the use of cookies.',
                primary_btn: {
                    text: 'Accept',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Privacy settings',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Description'},
                ],
                blocks: [
                    {
                        title: '',
                        description: 'You can specify your privacy settings below. Settings will be kept for 365 days. Detailed information and how you can withdraw your consent at any time can be found in our <a href="/en/policies/privacy">Privacy policy</a>.'
                    }, {
                        title: 'Necessary fonctionnal cookies',
                        description: '',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            {
                                col1: 'Cookies preferences',
                                col2: 'Allow to preserve preferences regarding data collection.',
                            },{
                                col1: 'User session',
                                col2: 'Allows you to keep certain actions performed during navigation such as language, filters and use our forms.',
                            },{
                                col1: 'Google ReCaptcha',
                                col2: 'Allows you to validate that you can use forms protected with a recaptcha.',
                            }
                        ]
                    }, {
                        title: 'Performance and analysis',
                        description: '',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'Google Analytics',
                                col2: 'Allows you to obtain information regarding the number of users present on the site and their usage, such as the number of pages viewed, bounce rate, average duration of a visit, origin of traffic, etc.',
                            }
                        ]
                    }, {
                        title: '',
                        description: 'You have the right to revoke your consent at any time, either individually or in its entirety. If consents to data processing are revoked, the data that were legally collected up to the revocation can still be processed by the provider.',
                    }
                ]
            }
        }
    },

    gui_options: {
        consent_modal: {
            layout: 'bar',               // box/cloud/bar
            position: 'bottom left',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            position: 'left',              // left/right
            transition: 'slide'            // zoom/slide
        }
    }
});
