function ActiveMenu(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.menu = $(obj);

    this.init();
}
ActiveMenu.prototype = {
    init: function() {
        var activePageID = this.getActivePageID();
        for (var i = activePageID.length - 1; i >= 0; i--) {
            this.menu.find('li.' + activePageID[i]).addClass(this.classes.active);
        }

        var currentPageId = this.getCurrentPageID();
        if(currentPageId){
            var currentPage = ' <span class="visuallyhidden">'+SAAQ.Helpers.translate('current_page')+'</span>';
            this.menu.find('li.' + currentPageId).find('a').first().append(currentPage);
        }
    },
    getActivePageID: function() {
        return $('body').attr('data-active-page') ? $('body').attr('data-active-page').split(',') : [];
    },
    getCurrentPageID: function() {
        return $('body').attr('data-current-page') ? $('body').attr('data-current-page') : null;
    }
};

VTT.extend.Components(ActiveMenu, 'ActiveMenu');
