function TilesNavigation(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({}, config);

    this.tilesNavigation = obj;
    this.tilesList = this.tilesNavigation.find('> ul');
    this.tiles = this.tilesNavigation.find('.js-tile');
    this.tileTriggers = this.tiles.find('.tile-trigger');
    this.tileContent = this.tiles.find('.tile-content');

    this.init();
}

TilesNavigation.prototype = {

    // Component initialization
    init: function() {

        this.tileContent.each(function(){

            var $guard = $('<button/>', {
                'class': 'js-guard-before visuallyhidden',
                'tabindex': '-1'
            });
            $(this).before($guard);

            var $guard = $('<button/>', {
                'class': 'js-guard-after visuallyhidden',
                'tabindex': '-1'
            });            
            $(this).after($guard);
        });

        // Bind events
        this.bindEvents();
    },
    // Bind events with actions
    bindEvents: function() {
        var self = this;
        
        self.tileTriggers.on('click', function() {

            var selectedTile = $(this).parents('.js-tile');
            
            if (selectedTile.hasClass(self.classes.open)) {
                self.closeTile(selectedTile);
            } else {
                self.openTile(selectedTile);
            }
        });
        self.tileContent.on('click', '.tile-close-btn', function() {

            var selectedTile = $(this).parents('.js-tile');
            self.closeTile(selectedTile);
        });

        self.tiles.on('focus', '.js-guard-before', function() {
            
            var selectedTile = $(this).parents('.js-tile');
            
            self.closeTile(selectedTile);
            selectedTile.find('.tile-trigger').focus();
        });
        self.tiles.on('focus', '.js-guard-after', function() {

            var selectedTile = $(this).parents('.js-tile');
            
            self.closeTile(selectedTile);
            selectedTile.next('.js-tile').find('.tile-trigger').focus();
        });

    },
    openTile: function (selectedTile) {
        var self = this,
            tileContent = selectedTile.find('.tile-content'),
            liIndex = selectedTile.index(),
            liMarginLeft = selectedTile.css('margin-left'),
            liWidth = selectedTile.width();

        tileContent.css({
            'display': 'block',
            'min-height': self.tilesNavigation.outerHeight() + 'px',
            'left': '-' + ((parseFloat(liMarginLeft) * liIndex) + (liWidth * liIndex)) + 'px',
            'position': 'absolute',
            'top': '0',
            'width': (self.tilesList.innerWidth() - (self.tilesList.innerWidth()*2.8/100 )) + 'px',
            'z-index': '2'
        });
        if ($(window).width() < 768) {
            tileContent.css({
                'min-height': self.tilesNavigation.outerHeight() + 'px',
                'left': '0',
                'position': 'absolute',
                'top': '0',
                'width': self.tilesNavigation.innerWidth() + 'px',
                'z-index': '2'
            });
        }       
        selectedTile.addClass(self.classes.open);

        tileContent.find('.tile-close-btn').attr('tabindex', '-1');
        tileContent.find('.tile-title').attr('tabindex', '-1').focus();

        selectedTile.find('.js-guard-before').removeAttr('tabindex');
        selectedTile.find('.js-guard-after').removeAttr('tabindex');

    },
    closeTile: function (selectedTile) {
        var self = this,
            tileContent = selectedTile.find('.tile-content');

        tileContent.css({
            'display': 'none',
            'min-height': '',
            'left': '',
            'position': 'relative',
            'top': '',
            'width': '',
            'z-index': ''
        });

        selectedTile.removeClass(self.classes.open);

        selectedTile.find('.js-guard-before').attr('tabindex', '-1');
        selectedTile.find('.js-guard-after').attr('tabindex', '-1');
    },    
};
VTT.extend.Components(TilesNavigation, 'TilesNavigation');