VTT.extend.Helpers({
    retina: function($el) {
        if (window.matchMedia) {
            var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");

            if (mq && mq.matches || (window.devicePixelRatio > 1)) {
                $el.attr('src', $el.attr('src').replace('@2x.png', '.png')).removeClass('is-retina');
            }
        }
    },
    eachChildTextNode: function(node, action) {
        if (node.nodeType === 3) {
            action(node);
        } else if (node.hasChildNodes()) {
            var i = node.childNodes.length;

            while (i > 0) {
                this.eachChildTextNode(node.childNodes[--i], action);
            }
        }
    },
    unbreakableHyphen: function($el) {
        $el.each(function() {
            eachChildTextNode(this, function(node) {
                node.nodeValue = node.nodeValue.replace(/-/g, "\u2011");
            });
        });
    },
    setHeightToCards: function(membershipCard) {
        if ($('.box-membership--small__rv').length) {
            if ($(window).width() < 768) {
                membershipCard.css('min-height', '0');
                membershipCard.removeClass('is-desktop');
            } else {
                membershipCard.css('min-height', '0');
                membershipCard.addClass('is-desktop');
                membershipCard.css('min-height', _.max($.makeArray(membershipCard.map(function() {
                    return $(this).outerHeight();
                }))));
            }
        }
    },
    setHeightToSidebarButtons: function() {
        if ($('.l-form.confirmation').length) {
            var buttonHeight,
                sidebarButtons = $('.sidebar').find('section > a'),
                sidebarButtonsHeight = _.max($.makeArray(sidebarButtons.map(function() {
                    return $(this).height();
                })));

            sidebarButtons.each(function() {
                var $this = $(this);
                if ($this.find('span').height() !== sidebarButtonsHeight) {
                    buttonHeight = parseInt((sidebarButtonsHeight - $this.find('span').height()) / 2);
                    if ($this.find('span').length) {
                        $this.css({
                            'padding-bottom': buttonHeight,
                            'padding-top': buttonHeight
                        });
                    } else {
                        buttonHeight = parseInt((sidebarButtonsHeight - $this.height()) / 2);
                        $this.css({
                            'padding-bottom': buttonHeight,
                            'padding-top': buttonHeight
                        });
                    }
                }
            });
        }
    },
    getUpdatedQueryString: function(paramKey, paramValue, url) {
        if (!url)
            url = window.location.href;
        var re = new RegExp("([?&])" + paramKey + "=.*?(&|#|$)(.*)", "gi");

        if (re.test(url)) {
            if (typeof paramValue !== 'undefined' && paramValue !== null)
                return url.replace(re, '$1' + paramKey + "=" + paramValue + '$2$3');
            else {
                var hash = url.split('#');
                url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                    url += '#' + hash[1];
                return url;
            }
        } else {
            if (typeof paramValue !== 'undefined' && paramValue !== null) {
                var separator = url.indexOf('?') !== -1 ? '&' : '?',
                    hash = url.split('#');
                url = hash[0] + separator + paramKey + '=' + paramValue;
                if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                    url += '#' + hash[1];
                return url;
            } else
                return url;
        }
    },
    translate: function(cle) {
        // Langue courante du site
        var LANG = $('html').attr('lang');

        if (typeof TYPO3.lang !== 'undefined') {
            // La clé existe, on la retourne
            if (cle in TYPO3.lang) {
                return TYPO3.lang[cle];
            }
        }
        // La clé n'a pas été traduite, on va au moins
        // retourner la clé pour le UI.
        return cle;
    },
    fillFormtime: function() {
        $(window).on('load', function() {
            $("input[name$='[formtime]']").val(Math.round(+new Date()/1000));
        });
    }
});
