function MobileContent(obj, config, app) {
    
    this.classes = app.Classes;
    this.mobileMenu = obj;

    // Mobile content
    this.mobileMenuContent = this.mobileMenu.find('.js-mobile-content');

    // Buttons
    this.openButton = this.mobileMenu.find('.js-mobile-trigger');
    this.closeButton = this.mobileMenu.find('.js-mobile-close');

    this.init();

}
MobileContent.prototype = {
    init: function() {
        this.bindEvents();
    },
    bindEvents: function() {
        var self = this;

        this.openButton.on('click', function() {
            self.open();
        });
        this.closeButton.on('click', function() {
            self.close();
        });

    },
    open: function () {
        this.mobileMenuContent.addClass('is-open');
        $('body').css('overflow', 'hidden');
    },
    close: function() {
        this.mobileMenuContent.removeClass('is-open');
        $('body').css('overflow', '');
    }   
};

VTT.extend.Components(MobileContent, 'MobileContent');
