function MobileHeader(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({
        menuFolders: null,
        searchFolders: null
    }, {}, config);

    this.mobileHeader = obj;
    this.body = $('body');
    this.html = $('html');
    this.heightMenuContainer = null;
    this.mainNav = this.mobileHeader.find('.main-nav-module');
    this.mainNavHeight = this.mainNav.outerHeight();
    this.completeHeaderHeight = this.mobileHeader.outerHeight();
    this.scrollTopValue = window.scrollY;
    this.whiteSectionHeight = this.completeHeaderHeight - this.mainNavHeight;
    this.mobileBreadcrumb = $('.mobile-breadcrumb-container');
    this.mainAndFooter = $('.l-main, .l-footer');
    this.linksToHide = $('.accessibilite a, a.piv-logo');
    this.currentMenuContainer = null;
    this.isActive = false;
    this.init();
}
MobileHeader.prototype = {
    init: function() {
        this.checkIfActive();
        this.bindEvents();
    },
    bindEvents: function() {
        var self = this;
        $(window).on('scroll', function(e) {
            if (self.isActive) {
                self.scrollTopValue = this.pageYOffset;
                self.checkIfSnapped();
            }
        });
        $(window).on('resize', _.debounce(function() {
            self.checkIfActive();
            if (self.isActive) {
                self.menuContainerAdjuster();
            } else {
                self.html.removeClass('l-no-scroll');
                self.mainNav.removeClass('is-snapped');

                self.resetStyles();
            }
        }, 100));
        $('.js-menu-trigger, .js-search-trigger').on('click', function(e) {
            var $button = $(e.currentTarget);
            if ($button.hasClass('is-active')) {
                self.unsnapMenu = true;
                $('.js-menu-trigger, .js-search-trigger').removeClass('is-active');
            } else {
                self.unsnapMenu = false;
                $('.js-menu-trigger, .js-search-trigger').removeClass('is-active');
                $button.addClass('is-active');
            }
        });
        $(document).on('onSearchOpen', function() {
            self.currentMenuContainer = $('.js-search-fold.is-open .search-content');
            if (self.isActive) {
                self.mainNav.addClass('is-snapped');
                self.menuContainerAdjuster();
                self.config.menuFolders.closeAll();
                self.html.addClass('l-no-scroll');
                self.mainAndFooter.attr('aria-hidden', true);
                self.mainAndFooter.find('a', 'area', 'button', 'input', 'object', 'select').attr('tabindex', -1);
                self.linksToHide.attr('aria-hidden', true);
                self.linksToHide.attr('tabindex', -1);
            }
        });
        $(document).on('equalElementNavigation', function() {

            $(document).ready(function(){
                var height = 0;
                var id = $('.main-section ul > li').data('equalheight');
                var elements = $('[data-equalheight~=' + id + ']');

                elements.each(function() {
                    var $this = $(this);
                    $this.css('height', 'auto');
                });
                setTimeout(function () {
                    elements.each(function() {
                        var $this = $(this);
                        var currentHeight = $this.outerHeight();
                        height = (currentHeight > height ? currentHeight : height);
                    });
                    elements.outerHeight(height);
                }, 1);
            });
        });
        $(document).on('onMenuOpen', function() {
            self.currentMenuContainer = $('.js-menu-fold.is-open .menu-container');
            if (self.isActive) {
                self.mainNav.addClass('is-snapped');
                self.menuContainerAdjuster();
                self.config.searchFolders.closeAll();
                self.html.addClass('l-no-scroll');
                self.mainAndFooter.attr('aria-hidden', true);
                var elementsToChange = self.mainAndFooter.find('a', 'area', 'button', 'input', 'object', 'select');
                elementsToChange.each(function(index, value) {
                    if ($(value).attr('tabindex')) {
                        $(value).attr('data-tabindex', $(value).attr('tabindex'));
                    }
                });
                elementsToChange.attr('tabindex', -1);
                self.linksToHide.attr('aria-hidden', true);
                self.linksToHide.attr('tabindex', -1);
            }
        });

        $(document).on('onMenuClose', function() {
            if (self.isActive) {
                if (self.scrollTopValue < self.whiteSectionHeight && self.unsnapMenu) {
                    self.mainNav.removeClass('is-snapped');
                }
                self.mainAndFooter.removeAttr('aria-hidden');
                var elementsToChange = self.mainAndFooter.find('a', 'area', 'button', 'input', 'object', 'select');
                elementsToChange.removeAttr('tabindex');
                elementsToChange.each(function(index, value) {
                    if ($(value).attr('data-tabindex')) {
                        $(value).attr('tabindex', $(value).attr('data-tabindex'));
                        $(value).removeAttr('data-tabindex');
                    }
                });
                self.linksToHide.removeAttr('aria-hidden');
                self.linksToHide.removeAttr('tabindex');
                self.resetStyles();
            }
        });
        $(document).on('onMenuClosed', function() {
            self.html.removeClass('l-no-scroll');
        });
        $(document).on('onSearchClose', function() {
            if (self.isActive) {
                if (self.scrollTopValue < self.whiteSectionHeight && self.unsnapMenu) {
                    self.mainNav.removeClass('is-snapped');
                }
                self.mainAndFooter.removeAttr('aria-hidden');
                self.mainAndFooter.find('a', 'area', 'button', 'input', 'object', 'select').removeAttr('tabindex');                
                self.linksToHide.removeAttr('aria-hidden');
                self.linksToHide.removeAttr('tabindex');
                self.resetStyles();
            }
        });

        $(document).on('onSearchClosed', function() {
            self.html.removeClass('l-no-scroll');
        });
    },
    checkIfActive: function() {
        if (window.innerWidth < 768) {
            this.isActive = true;
        } else {
            this.isActive = false;
        }
    },
    checkIfSnapped: function() {
        var openChildren = this.mainNav.find('.is-open');

        if (this.scrollTopValue >= this.whiteSectionHeight) {

            $('.main-nav-module, .mobile-breadcrumb-container').addClass('is-snapped');


            this.body.addClass('has-snapped-nav');

            if (this.mobileBreadcrumb.length > 0) {
                this.body.addClass('has-mobile-breadcrumb');
            }
        } else {
            if (openChildren.length == 0) {
                $('.main-nav-module, .mobile-breadcrumb-container').removeClass('pre-snap');
                $('.main-nav-module, .mobile-breadcrumb-container').removeClass('is-snapped');
                this.body.removeClass('has-snapped-nav has-mobile-breadcrumb');
            }
        }
    },
    menuContainerAdjuster: function() {
        if (this.currentMenuContainer && (this.config.menuFolders.folds.filter('.is-open').length > 0 || this.config.searchFolders.folds.filter('.is-open').length > 0)) {
            this.mainNav.addClass('is-snapped');
            this.html.addClass('l-no-scroll');
            var heightMenuContainer = this.currentMenuContainer.outerHeight();
            var wInHeight = $(window).innerHeight();
            var wOutHeight = $(window).outerHeight();
            if (window.height > wInHeight) {
                wInHeight = window.height;
            }
            if (wOutHeight > wInHeight) {
                wInHeight = wOutHeight;
            }
            var cssObj = {
                'height': wInHeight - this.mainNavHeight + "px",
                'overflow-y': 'auto',
                '-webkit-overflow-scrolling': 'touch'
            };
            this.currentMenuContainer.css(cssObj);
        }
    },
    resetStyles: function() {

        if (this.currentMenuContainer) {
            this.currentMenuContainer.css({
                'height': '',
                'overflow-y': '',
                '-webkit-overflow-scrolling': ''
            });
        }
    }
};
VTT.extend.Components(MobileHeader, 'MobileHeader');