function TriBlock(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({
    }, config);

    this.triblock = $(obj);
    this.loneBlock = this.triblock.find('.tri-block-block.is-lone');
    this.stackedBlocks = this.triblock.find('.tri-block-block.is-stacked');
    this.blocks = [this.loneBlock, this.stackedBlocks];
    this.marginReference = $('.l-header .l-wrapper').first();

    this.init();
}

TriBlock.prototype = {
    init: function() {
        this.bindEvents();
    },
    pickOneOfMany: function() {
        $('.tri-block .one-of-many').each(function() {

            var i,
                j,
                rand,
                elements = $(this).find('.element'),
                weightedArray = [];

            for (i = 0; i < elements.length; i++) {
                for (j = 0; j < elements.eq(i).data('weight'); j++) {
                    weightedArray.push(i);
                }
            }
            rand = weightedArray[Math.floor(Math.random() * weightedArray.length)];
            elements.eq(rand).show();

        });
    },
    bindEvents: function() {
        var self = this,
            $window = $(window);

        $window.on('load', function() {
            self.pickOneOfMany();
            self.setStyles();
        });
        $window.on('resize', function(){
            self.setStyles();
        });
    },
    setStyles: function() {
        var marginWidth = this.marginReference.offset().left;

        $.each(this.blocks, function(){
        	if(window.innerWidth > 768) {
        		$(this).find('.tri-block-fake-col').css({
        			margin: '0',
        			width: 'calc(100% - ' + marginWidth + 'px)'
        		});
        	} else {
        		$(this).find('.tri-block-fake-col').css({
        			'margin': '0',
        			'width': 'calc(100% - ' + (marginWidth * 2) + 'px)'
        		});
        	}
        });

        var stackedHeight = 0;
        $.each(this.stackedBlocks, function(){
            stackedHeight += $(this).outerHeight();
        });

        if(window.innerWidth > 1024) {
        	this.loneBlock.find('.tri-block-fake-col').css({
        		'margin-right': marginWidth + 'px',
        		'min-height': stackedHeight + "px"
        	});
        	this.stackedBlocks.find('.tri-block-fake-col').css('margin-left', marginWidth + 'px');
        } else if (window.innerWidth <= 1024 && window.innerWidth > 767 ) {
        	this.loneBlock.find('.tri-block-fake-col').css({
        		'margin-right': marginWidth + 'px',
        		'min-height': '0'
        	});
        	this.stackedBlocks.find('.tri-block-fake-col').css('margin-left', marginWidth + 'px');
        } else {
        	this.loneBlock.find('.tri-block-fake-col').css({
        		'margin' : '0 ' + marginWidth + 'px',
        		'min-height' : '0'
        	});
        	this.stackedBlocks.find('.tri-block-fake-col').css('margin', '0 ' + marginWidth + 'px');
        }
    }
};

VTT.extend.Components(TriBlock, 'TriBlock');
