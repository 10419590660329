function Process(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({}, {

    }, config);

    this.process = obj;
    this.folder = SAAQ.Components.ProcessFolders;

    this.processSteps = this.process.find('.processStep');

    this.init();
}

Process.prototype = {
    init: function() {
        this.bindEvents();
    },

    bindEvents: function() {
        $(window).on('resize', $.proxy(function() {
            if ($(window).width() <= 890) {
                this.createLayout();
            } else {
                this.resetLayout();
            }
        }, this)).trigger('resize');
    },

    createLayout: function() {
        this.process.addClass('is-mobile-process');
    },

    resetLayout: function() {
        this.process.removeClass('is-mobile-process');
    }
};

VTT.extend.Components(Process, 'Process');
