function Equalize(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({}, config);

    this.equalize = $(obj);
    this.columns = this.equalize.children();
    this.columnsInner = this.columns.find('> div');
    this.offTop = parseFloat(this.columnsInner.css('padding-top'));
    this.offBottom = parseFloat(this.columnsInner.css('padding-bottom'));

    this.init();
}

Equalize.prototype = {
    init: function() {
        this.setStyles();
        this.bindEvents();
    },
    bindEvents: function() {
        var self = this,
            $window = $(window);

        $window.on('load', function() {
            self.resizeIt();
        });
        $window.resize(function() {
            self.resizeIt();
        });
    },
    setStyles: function() {
        this.equalize.addClass('clearfix');
        this.columns.css({ 'float': 'left', 'width': '48%' }).eq(1) .css('margin-left', '2%');
    },
    resizeIt: function() {
        var self = this,
            height;

        self.columnsInner.css('min-height', '0');
        self.columnsInner.css('min-height', _.max($.makeArray(self.columns.map(function() { return $(this).height(); }))));
    }
};

VTT.extend.Components(Equalize, 'Equalize');
