function SocialModule(obj, config, app) {
	/*
		*****************************************************************
		Ce module nécessite la librairie sharrre.js (http://sharrre.com/)
		*****************************************************************
	*/

    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({
    }, {}, config);

    this.socialModule  = obj;
    this.twitterShare  = this.socialModule.find('.is-tw');
    this.facebookShare = this.socialModule.find('.is-fb');
    this.googleShare   = this.socialModule.find('.is-gg');
    this.mailShare	   = this.socialModule.find('.is-email');

    this.init();
}

SocialModule.prototype = {
	init: function() {
		this.twitterShare.sharrre({
			share: {
			},
			enableHover: false,
			click: function(api, options){
				api.simulateClick();
				api.openPopup('twitter');
			},
			url: window.location
		});

		this.facebookShare.sharrre({
			share: {
			},
			enableHover: false,
			click: function(api, options){
				api.simulateClick();
				api.openPopup('facebook');
			},
			url: window.location
		});

		this.googleShare.sharrre({
			share: {
			},
			enableHover: false,
			click: function(api, options){
				api.simulateClick();
				api.openPopup('googlePlus');
			},
			url: window.location
		});
	}
};
VTT.extend.Components(SocialModule, 'SocialModule');
