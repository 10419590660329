/** Create project */
var app = VTT.create('SAAQ');

app.setComponent('MenuFolders', 'Folder', $('.js-menu-folder'), {
    foldClass: 'js-menu-fold',
    buttonClass: 'js-menu-trigger',
    uniqueFold: true,
    durationOpen: 100,
    durationClose: 100,
    a11yAria: false,
    beforeOpen: function() {
        $(document).trigger('onMenuOpen');
        // Fix to prevent wierd select height
        $(document).trigger('equalElementNavigation');
        $('.services-points-tool.is-teaser select').css('height', '1.563em');
    },
    beforeClose: function() {
        $(document).trigger('onMenuClose');
    },
    callbackClose: function() {
        $(document).trigger('onMenuClosed');
    },
    callbackOpen: function() {
        // Fix to prevent wierd select height
        $('.services-points-tool.is-teaser select').css('height', 'auto');
    }
});

app.setComponent('SearchFolders', 'Folder', $('.js-search-folder'), {
    foldClass: 'js-search-fold',
    buttonClass: 'js-search-trigger',
    openFirst: true,
    ariaTextOpen: 'Cliquer pour ouvrir la recherche',
    ariaTextClose: 'Cliquer pour fermer la recherche',
    beforeOpen: function() {
        $(document).trigger('onSearchOpen');
    },
    beforeClose: function() {
        $(document).trigger('onSearchClose');
    },
    callbackClose: function() {
        $(document).trigger('onSearchClosed');
    }
});

app.setComponent('MobileHeaders', 'MobileHeader', $('.l-header'), {
    menuFolders: app.Components.MenuFolders,
    searchFolders: app.Components.SearchFolders
});


app.setComponent('MobileContents', 'MobileContent', $('.js-mobile'));

app.setComponent('ServiceEnLignes', 'ServiceEnLigne', $('.service-en-ligne-ajax'));


app.setComponent('ServicesFolders', 'Folder', $('.js-services-folder'), {
    foldClass: 'js-services-fold',
    buttonClass: 'js-services-trigger',
    contentWrapClass: 'js-services-content',
    ariaTextOpen: 'Cliquer pour ouvrir cette option',
    ariaTextClose: 'Cliquer pour fermer cette option',
    unbuttonizeTag: 'h3',
});

app.setComponent('SectionsFolders', 'Folder', $('.js-sections-fold .js-folder'), {
    foldClass: 'js-fold',
    buttonClass: 'js-sections-trigger',
    contentWrapClass: 'js-sections-content',
    unbuttonizeTag: 'h2'
});

app.setComponent('ProcessFolders', 'Folder', $('.js-processStep-folder'), {
    foldClass: 'js-processStep-fold',
    buttonClass: 'js-processStep-trigger',
    contentWrapClass: 'js-processStep-content',
    unbuttonizeTag: 'span'
});


//app.setComponent('ProcessFolders', 'Process', $('.js-processStep-folder'), {
//     foldClass: 'js-processStep-fold',
//     buttonClass: 'js-processStep-trigger',
//     contentWrapClass: 'js-processStep-content',
//     unbuttonizeTag: 'h2',
//     durationOpen: 400,
//     durationClose: 400,
//     easing: 'linear'
// //});

app.setComponent('idriveFolders', 'Folder', $('.js-idrive-folder'), {
    foldClass: 'js-idrive-fold',
    buttonClass: 'js-idrive-trigger',
    contentWrapClass: 'js-idrive-content',
    animate: true,
    durationOpen: 350,
    durationClose: 350,

});

app.setComponent('LoadingLists', 'LoadingList', $('.js-loading-list').filter(':not([data-bypage=""])'));
app.setComponent('BacksNews', 'BackNews', $('.js-backnews'));

app.setComponent('FiltersFolders', 'Folder', $('.js-filter-folder'), {
    foldClass: 'js-filter-fold',
    buttonClass: 'js-filter-trigger',
    contentWrapClass: 'js-filter-content',
    ariaTextOpen: 'Cliquer pour ouvrir les filtres de recherche',
    ariaTextClose: 'Cliquer pour fermer les filtres de recherche',
    beforeOpen: function() {
        $('.js-filter-trigger').text('Masquer les filtres de recherche');
    },
    beforeClose: function() {
        $('.js-filter-trigger').text('Afficher les filtres de recherche');
    }
});

app.setComponent('FacetFolders', 'Folder', $('.js-facets-folder'), {
    a11yAria: false,
    ariaTextOpen: ' Cliquer pour ouvrir',
    ariaTextClose: ' Cliquer pour fermer',
});


app.setComponent('TabCamp', 'Tab', $('.tab-campaign'), {
    tabWrapperClass: 'tab-wrapper-campaign',
    tabTriggerClass: 'tab-trigger-campaign',
    tabContentClass: 'tab-content-campaign',
});

app.setComponent('Filters', 'Filter', $('.widget'));
app.setComponent('Tabs', 'Tab', $('.tab'));
app.setComponent('Drives', 'Drive', $('.js-idrive-folder'));
app.setComponent('TilesNavigations', 'TilesNavigation', $('.js-tiles'));
app.setComponent('Folders', 'Folder', $('.js-folder'));
app.setComponent('QuantityInputs', 'QuantityInput', $('.js-quantity-input'));
app.setComponent('ShoppingCarts', 'ShoppingCart', $('.js-shopping-cart'));
app.setComponent('ActiveMenu', 'ActiveMenu', $('.menu-container'));

app.setComponent('Organigrammes', 'Folder', $('.js-folder-organigramme'), {
    hasContextualLabel: true,
});
app.setComponent('Forms', 'Form', $('.js-validation'));

app.setComponent('TableControllers', 'TableController', $('.js-controlled-table'));

app.setComponent('Tooltips', 'Tooltip', $('.tooltip'), {
    a11yText: SAAQ.Helpers.translate("tooltip_ally")
});

$(document).on('ajaxComplete', function() {

    app.setComponent('Tooltips', 'Tooltip', $('.tooltip'));

});

app.setComponent('SocialModules', 'SocialModule', $('.social-footer .social-module'));

app.setComponent('SubnavFlow', 'FlowText', $('.js-flow-subnav'), {
    minFontSize: 15,
    maxFontSize: 18,
    minScreenWidth: 650,
    maxScreenWidth: 1180
});
app.setComponent('SubnavFlow', 'FlowText', $('.js-flow-main-title'), {
    minFontSize: 13,
    maxFontSize: 16,
    minScreenWidth: 320,
    maxScreenWidth: 1024
});
app.setComponent('NeedInfoSubs', 'FlowText', $('.js-flow-need-info'), {
    minFontSize: 18,
    maxFontSize: 20,
    minScreenWidth: 1024,
    maxScreenWidth: 1180
});
app.setComponent('HubTitle', 'FlowText', $('.js-flow-hub-title'), {
    minFontSize: 22,
    maxFontSize: 55,
    minScreenWidth: 400,
    maxScreenWidth: 1024
});
app.setComponent('driveTitle', 'FlowText', $('.js-flow-idrive-title'), {
    minFontSize: 22,
    maxFontSize: 55,
    minScreenWidth: 767,
    maxScreenWidth: 1024
});
app.setComponent('CampaignFilterTitle', 'FlowText', $('.js-flow-campaign-filter-title'), {
    minFontSize: 25,
    maxFontSize: 28,
    minScreenWidth: 320,
    maxScreenWidth: 520
});
app.setComponent('CampaignFilters', 'FlowText', $('.js-flow-campaign-filter'), {
    minFontSize: 15,
    maxFontSize: 18,
    minScreenWidth: 320,
    maxScreenWidth: 340
});
app.setComponent('FooterTitlesFlow', 'FlowText', $('.js-flow-footer-title'), {
    minFontSize: 22,
    maxFontSize: 24,
    minScreenWidth: 767,
    maxScreenWidth: 1180
});

app.setComponent('FooterQuotesFlow', 'FlowText', $('.js-flow-quote'), {
    minFontSize: 16,
    maxFontSize: 18,
    minScreenWidth: 767,
    maxScreenWidth: 1180
});

app.setComponent('DatePickers', 'DatePicker', $('.datepicker'), {
    selectMonths: false,
    selectYears: false
});

app.setComponent('DatePickers', 'DatePicker', $('.datepicker-with-dropdown'), {
    selectMonths: true,
    selectYears: true
});

app.setComponent('DatePickers', 'DatePicker', $('.datepicker-birthday'), {
    selectMonths: true,
    selectYears: true,
    birthday: true
});

app.setComponent('TriBlocks', 'TriBlock', $('.tri-block'));

app.Commons.resetMenuWidth();

$(window).on('resize', function() {
    app.Commons.resetMenuWidth();
});

if ($('.tx-felogin-pi1 .error').length > 0) {
    var prependText = SAAQ.Helpers.translate("error_prefix");
    var errorText   = ' - ' + $('.tx-felogin-pi1 .error').text();

    $('.tx-felogin-pi1 label[for="user"]').prepend(prependText);
    $('.tx-felogin-pi1 label[for="pass"]').prepend(prependText);

    $('.tx-felogin-pi1 label[for="user"]').append(errorText);
    $('.tx-felogin-pi1 label[for="pass"]').append(errorText);
}

// Mobile
if (!$('html').hasClass('ie8')) {
    enquire.register('screen and (max-width:767px)', {
        match: function() {
            if (app.Components.SearchFolders) {
                $(app.Components.SearchFolders).each(function() {
                    this.closeAll();
                });
            }
            if (app.Components.ServicesFolders) {
                $(app.Components.ServicesFolders).each(function() {
                    this.activate();
                });
            }
            if (app.Components.Tabs) {
                $(app.Components.Tabs).each(function() {
                    this.closeAll();
                    this.enableCloseClick();
                });
            }
            if (app.Components.FiltersFolders) {
                $(app.Components.FiltersFolders).each(function() {
                    this.closeAll();
                    this.activate();
                });
            }
            if (app.Components.SectionsFolders) {
                $(app.Components.SectionsFolders).each(function() {
                    this.closeAll();
                    this.activate();
                });
            }
            if (app.Components.idriveFolders) {
                $(app.Components.idriveFolders).each(function() {
                    this.closeAll();
                    this.activate();
                });
            }
        }
    });
    enquire.register('screen and (min-width:768px)', {
        match: function() {
            if (app.Components.SearchFolders) {
                $(app.Components.SearchFolders).each(function() {
                    this.openAll();
                    this.changeAreaState();
                });
            }
            if (app.Components.ServicesFolders) {
                $(app.Components.ServicesFolders).each(function() {
                    this.openAll();
                    this.deactivate();
                });
            }
            if (app.Components.Tabs) {
                $(app.Components.Tabs).each(function() {
                    this.disableCloseClick();
                });
            }
            if (app.Components.FiltersFolders) {
                $(app.Components.FiltersFolders).each(function() {
                    this.openAll();
                    this.deactivate();
                });
            }
            if (app.Components.SectionsFolders) {
                $(app.Components.SectionsFolders).each(function() {
                    this.deactivate();
                });
            }
            if (app.Components.idriveFolders) {
                $(app.Components.idriveFolders).each(function() {
                    this.openAll();
                    this.deactivate();
                });
            }
            $('.main-nav-modulegit, .mobile-breadcrumb-container').removeClass('is-snapped');
            $('body').removeClass('has-snapped-nav has-mobile-breadcrumb');
        }
    });

    enquire.register('screen and (min-width:961px)', {
        match: function() {}
    });

    enquire.register('screen and (max-width:960px)', {
        match: function() {},
        unmatch: function() {}
    });

    enquire.register('screen and (max-width:1024px)', {
        match: function() {},
        unmatch: function() {}
    });

    enquire.register('screen and (max-width:360px)', {
        match: function() {},
        unmatch: function() {}
    });
}

var $body = $('body');

$body.on('mousemove.LibeoDataState', function(e) {
    if ($body.attr('data-state')) {
        $body.removeAttr('data-state');
    }
    $body.off('mousemove.LibeoDataState');
});

/* lbo_alert_messages */

jQuery(document).ready(function($) {

    svg4everybody({
    	polyfill: true // polyfill <use> elements for External Content
    });

    $('.alert-message').each(function(key,value) {
        var $this = $(this);
        var elemHeight = $this.find('.alert-click').height() + $this.find('.alert-content').outerHeight();
        var closedBaseHeight = $this.find('.alert-click').height();
        var environment = $('body').data('environment');
        var open = $this.hasClass('open');

        $this.attr('baseHeight', elemHeight);
        $this.attr('closedHeight', closedBaseHeight);

        $this.css('height', closedBaseHeight + 'px');

        if (environment == 'desktop' && open == 1) {

            var currentButton = $this.children('button.alert-click');
            currentButton.addClass('opened-alert');
            $this.css('height', elemHeight + 'px');

        }

        $this.find('.alert-click').click(function(e) {
            e.preventDefault();

            var $currentButton = $(this);

            if ($currentButton.hasClass('opened-alert')) {

                var currentAlert = $currentButton.parents('.alert-message').first();
                var parentClosedHeight = currentAlert.attr('closedHeight');

                $currentButton.removeClass('opened-alert');
                currentAlert.css('height', parentClosedHeight + 'px');

            } else {

                var currentAlert = $currentButton.parents('.alert-message').first();

                $currentButton.addClass('opened-alert');
                var elemHeight = $this.find('.alert-click').height() + $this.find('.alert-content').outerHeight();
                currentAlert.css('height', elemHeight + 'px');

            }
        });

        $this.find('.alert-message-content').first().css('visibility', 'visible');
    });
});

window.ValiderCookieActive = function (event) {
    event.preventDefault();
    var path = location.pathname;
    if(!validateURLIsInAllowedURL(path) && validateUrlIsInREquiredURLForCookieChecking(path)) {
        var cookiesEnabled = navigator.cookieEnabled;
        if (cookiesEnabled) {
            cookiesEnabled = "cookie" in document;
            cookiesEnabled = (cookiesEnabled && (document.cookie.length > 0 ||
                (document.cookie = "test").indexOf.call(document.cookie, "test") > -1));
        }

        if (!cookiesEnabled) {
            var urlSepare = location.pathname.split('/');
            var maintenant = new Date();

            var dateErreur = maintenant.getFullYear() + '-' + (maintenant.getMonth() + 1) + '-' + maintenant.getDate();
            dateErreur += '%20' + maintenant.getHours() + ':' + maintenant.getMinutes() + ':' + maintenant.getSeconds();

            var langue = ['en', 'fr'].indexOf(urlSepare[1].toLowerCase()) > -1 ? urlSepare[1] : 'fr';
            var statut = 'cookies';

            location.href = 'https://services.saaq.gouv.qc.ca/FonctionsWeb/Erreurs.web/Erreur.html?DateErreur=' + dateErreur + '&Langue=' + langue + '&Status=' + statut;
        } else {
            location.href = $(event.target).attr('href');
        }
    } else {
        location.href = $(event.target).attr('href');
    }
};

function validateURLIsInAllowedURL(path) {
    return validateUrlFromPath(config.getAllowedURL(), path);
}

function validateUrlIsInREquiredURLForCookieChecking(path){
    return validateUrlFromPath(config.getUrlForCookieChecking(), path);
}

function validateUrlFromPath(paths, path) {
    for(var i = 0; i < paths.length; i ++) {
        var currentPathObject = paths[i];
        if(validateUrl(currentPathObject, path)) {
            return true;
        }
    }
    return false;
}

function validateUrl(urlObject, path) {
    return urlObject.getType() === 2 ? validateRecursiveUrl(urlObject, path) : validateNonRecursiveUrl(urlObject, path);
}

function validateRecursiveUrl(urlObject, path) {
    return new RegExp('(' + urlObject.getUrl() + '){1}(.+)').test(path);
}

function validateNonRecursiveUrl(urlObject, path) {
    return urlObject.getUrl() === path;
}

// Disable submit button on submit
jQuery(document).ready(function($) {
    $('[data-disableonsubmit]').closest('form').on('submit', function(e){
       var btn = $(this).find('[data-disableonsubmit]');
       if(btn.data('hasSubmited')){
           e.preventDefault();
           return;
       }
       btn.data('hasSubmited',true);
    });
});

// Custom button cookie settings
document.addEventListener('DOMContentLoaded', function() {
    const cBnsDiv = document.getElementById('c-bns');

    if (cBnsDiv !== null) {
        const button = document.createElement('button');

        button.setAttribute('data-cc', 'c-settings');
        button.setAttribute('class', 'c-bn c_link');
        button.setAttribute('id', 'c-s-bnc');

        const language = document.documentElement.getAttribute('lang');

        const buttonText = {
            'en': 'Edit settings',
            'fr': 'Paramétrer les fichiers témoins',
        };

        button.textContent = buttonText[language] || buttonText.fr;

        button.setAttribute('type', 'button');

        cBnsDiv.appendChild(button);

        button.addEventListener('click', function() {
            cc.showSettings();
            setTimeout(function() {
                document.querySelector('#s-cnt').focus();
            }, 30);
        });
    }
});

var $backToTopBtn = $('#btn-back-to-top');
$(window).on('scroll', function() {
    if ($(window).scrollTop() > 20) {
        $backToTopBtn.fadeIn();
    } else {
        $backToTopBtn.fadeOut();
    }
});

$backToTopBtn.on('click', backToTop);

function backToTop() {
    $('html, body').animate({ scrollTop: 0 });
}
