VTT.extend.Commons({
    webfont: function() {
        WebFont.load({
            google: {
                families: ['Lato:300,400,700,300italic,400italic,700italic', 'Ubuntu:300,400,500,700']
            },
            active: function() {
                $(document).trigger('fontsLoaded');
            }
        });
    },
    gotocontent: function() {
        $('.goto-content-link').on('click', function(e) {
            var $contentDiv = '';
            if ($('.l-content').length > 0) {
                $contentDiv = $('.l-content');
            } else if ($('.l-main').length > 0) {
                $contentDiv = $('.l-main');
            }
            if ($contentDiv) {
                $(document).scrollTop( $contentDiv.eq(0).offset().top );
                $contentDiv.find('h1').eq(0).attr('tabindex', '-1').focus();
                e.preventDefault();
            }
        });
    },
    godown: function() {
        $('.js-down').on('click', function() {
            var $this = $(this),
                $target = $this.closest('.l-section').next('.l-section').find('header');
            $('body:not(:animated), html:not(:animated)').animate({
                scrollTop: $target.offset().top - 10
            }, 400);
        });
    },
    showVehiculeLabel: function() {
        $('.i-drive-options a').on('mouseover focusin', function() {
            var vehicule = $(this).find('.i-drive-option-label').text();
            $('.i-drive-selected-option').html(vehicule);
        });
        $('.i-drive-options a').on('mouseout focusout', function() {
            $('.i-drive-selected-option').html("");
        });
    },
    triggerButton: function() {
        $('.tab-trigger').on('click', function() {
            $('.tab-wrapper').removeClass('is-active').filter('[data-show="' + $(this).attr('data-show') + '"]')
                .addClass('is-active');
            $('.tab-trigger').removeClass('is-active').filter('[data-show="' + $(this).attr('data-show') + '"]')
                .addClass('is-active');

        });
    },
    removeColumnProcess: function() {
        $(window).on('resize', function() {
            $('.contextual-content > ul').each(function() {
                if ($(this).parents('.folder-content').length == 0) {
                    if ($('body').outerWidth() <= 1185) {
                        $(this).removeClass('column-2');
                    }
                    else {
                        $(this).addClass('column-2');
                    }
                }
            });
        });
    },
    checkBackground: function() {
        $('.ir').each(function() {
            var $this = $(this);
            $.ajax({
                url: $this.css('background-image').replace(/"/g, '').replace(/url\(|\)$/ig, ''),
                type: 'HEAD',
                error: function() {
                    $this.removeClass('ir').css('width', 'auto');
                }
            });
        });
    },
    securedList: function() {
        var $secured = $('.secured-files');

        if ($('body').hasClass('lt-ie9') && $secured.length) {
            $secured.find('li:nth-child(odd)').addClass('is-third');
            $secured.find('li:first-child').removeClass('is-third');
        }
    },
    androidOrientationFix: function() {
        if ($('.membership__options ').length) {
            if (navigator.userAgent.match(/Android/i)) {
                window.addEventListener('orientationchange', function() {
                    document.querySelector('body').style.display = 'none';
                    setTimeout(function() {
                        document.querySelector('body').style.display = 'block';
                        CAA.Helpers.equalheights($('.box-membership-equalize'), 'min');
                    }, 1);
                }, false);
            }
        }
    },
    youtubeScroll: function() {
        if ($('.tx-youtubevideos-pi1').length) {
            $('#videos2').on('click', '.mediaDetail', function() {
                var topEl = $('.tx-youtubevideos-pi1'),
                    styles = $('body').attr('data-state') === 'keyboard' ? '2px solid #f51420' : 'none';

                $('body:not(:animated), html:not(:animated)').animate({
                    scrollTop: topEl.position().top
                }, 500, 'easeOutQuad', function() {
                    topEl.eq(0).attr('tabindex', '-1').focus().css('outline', styles).on('focusout', function() {
                        $(this).css('outline', 'none');
                    });
                });
            });
        }
    },
    a11ySearchPagination: function() {
        var tempHtml,
            pagination = $('.js-pagination');

        if (pagination.length) {
            tempHtml = pagination.html();
            pagination.html('').html(tempHtml);
        }
    },
    changeOrder: function() {
        var $container = $('[data-changeorder=container]'),
            $changeOrder = $('[data-changeorder]').not($container),
            originalState = $changeOrder.map(function() {
                return $(this);
            }),
            newState = [];

        $(window).on('resize', function() {
            if (Modernizr.mq('only all and (max-width:767px)')) {
                $changeOrder.each(function() {
                    newState[$(this).data('changeorder')] = $(this).detach();
                });
                $.each(newState, function(i, el) {
                    $container.append(el);
                });
            } else {
                $.each(originalState, function(i, el) {
                    $container.append($(el).detach());
                });
            }
        });
    },
    equalheight: function() {
        var identifiers = [];

        $(window).on('resize', _.debounce(function() {
            $('[data-equalheight]').each(function() {
                var id = $(this).data('equalheight');
                if (!_.contains(identifiers, id)) identifiers.push(id);
            });

            _.forEach(identifiers, function(id) {
                var height = 0,
                    elements = $('[data-equalheight=' + id + ']');

                elements.waitForImages(function() {
                    elements.each(function() {
                        var $this = $(this),
                            currentHeight = 0;

                        $this.css('height', 'auto');
                        currentHeight = $this.outerHeight();
                        height = (currentHeight > height ? currentHeight : height);
                    });
                    elements.outerHeight(height);
                });
            });
        }, 100)).trigger('resize');
        $(document).on('fontresize', function(){
            $(window).trigger('resize');
        })
    },
    resetMenuWidth: function() {
    },
    mainNavGuard: function() {

        var lang = $('html').attr('lang');

        $('.is-menu .js-guard').on('focus', function() {
            if ($('.is-menu').hasClass('is-open')) {
                $('.is-menu .js-menu-trigger').trigger('click');
                $('.is-services .js-menu-trigger').focus();
                $('.l-region').html((lang === 'en') ? 'Main menu has been closed' : 'Le menu principal a été fermé');
            }
        });
        $('.is-services .js-guard').on('focus', function() {
            if ($('.is-services').hasClass('is-open')) {
                $('.is-services .js-menu-trigger').trigger('click');
                $('.is-points .js-menu-trigger').focus();
                $('.l-region').html((lang === 'en') ? 'Online services menu has been closed' : 'Le menu Services a été fermé');
            }
        });
        $('.is-points .js-guard').on('focus', function() {
            if ($('.is-points').hasClass('is-open')) {
                $('.is-points .js-menu-trigger').trigger('click');
                $('.js-search-folder input[type="search"]').focus();
                $('.l-region').html((lang === 'en') ? 'Service outlet menu has been closed' : 'Le menu Points de service a été fermé');
            }
        });
    },
    splitTileLists: function() {

        if ($('.tile-content').length > 0) {

            $('.tile-content ul').each(function() {
                var list = $(this),
                    len = list.children().length,
                    size = Math.floor(len / 3),
                    current_size = 0;

                if (len > 6) {
                    list.children().each(function() {

                        if (++current_size > size) {
                            var new_list = $("<ul></ul>").addClass('splitlist').insertAfter(list);
                            list = new_list;
                            current_size = 1;
                        }
                        list.addClass('splitlist').append(this);
                    });
                }
            });
        }

    },
    pickOneOfMany: function() {
        $(window).on('load', function() {

            $('.align-two-blocks .one-of-many').each(function() {

                var i,
                    j,
                    rand,
                    elements = $(this).find('.element'),
                    weightedArray = [];

                for (i = 0; i < elements.length; i++) {
                    for (j = 0; j < elements.eq(i).data('weight'); j++) {
                        weightedArray.push(i);
                    }
                }
                rand = weightedArray[Math.floor(Math.random() * weightedArray.length)];
                elements.eq(rand).show();

            });

            $('.jobs .one-of-many').each(function() {

                var i,
                    j,
                    rand,
                    elements = $(this).find('.element'),
                    weightedArray = [];

                for (i = 0; i < elements.length; i++) {
                    for (j = 0; j < elements.eq(i).data('weight'); j++) {
                        weightedArray.push(i);
                    }
                }
                rand = weightedArray[Math.floor(Math.random() * weightedArray.length)];
                elements.eq(rand).show();

            });


        });
    },
    ie11BackgroundHeightFix: function() {
        $(window).on('load', function() {
            if ($('.ie11').length) {

                $('.align-block .fake-col').each(function(){
                    $(this).css('min-height', $(this).parent().height());
                });
            }
        });
    },
    twoblocksWidthFixer: function() {
        $(window).on('load', function() {
            $('.align-two-blocks').each(function(){
                $(this).parent().css('overflow', 'hidden');
            });
        });
    }
});
