function SideAccordion(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({}, {
        triggerClass: 'js-trigger',
        itemClass: 'js-item',
        wrapperClass: 'js-wrapper',
        contentClass: 'js-content',
        coverClass: 'js-cover'
    }, config);

    this.sideAccordion = obj;
    this.sideAccordionItems = this.sideAccordion.find('.' + this.config.itemClass);
    this.sideAccordionTriggers = this.sideAccordionItems.find('.' + this.config.triggerClass);
    this.sideAccordionOpenTriggers = this.sideAccordionItems.find('.' + this.config.triggerClass + '[data-action="open"]');
    this.sideAccordionTContent = this.sideAccordionItems.find('.' + this.config.contentClass);
    
    this.init();
}

SideAccordion.prototype = {
    init: function() {
        this.bindTriggers();
        this.bindWindow();
    },
    bindTriggers: function() {
        this.sideAccordionItems.on('click','button', $.proxy(function(e) {
            var $target = $(e.currentTarget);

            if (!Modernizr.mq('only all and (max-width: 768px)')) {
                this.sideAccordion.find('.' + this.config.itemClass + '.' + this.classes.open).removeClass(this.classes.open);
                this.sideAccordion.find('.' + this.config.itemClass + '.is-narrow').removeClass('is-narrow');
                this[$target.data('action') + 'Accordion']($target.closest('.' + this.config.itemClass));
            }

        }, this));
    },
    bindWindow: function() {
        $(window).on('resize', $.proxy(function() {
            if ($(window).width() < 768) {
                this.sideAccordionItems.find('.' + this.config.contentClass).css('height', '');
                this.sideAccordion.removeClass('has-open');
                this.sideAccordionItems.removeClass('.' + this.config.contentClass);
            }
        }, this)).trigger('resize');
    },
    openAccordion: function($item) {
        this.sideAccordion.addClass('has-open');
        $item.find('.' + this.config.contentClass).css('height', $item.find('.' + this.config.triggerClass).outerHeight());

        setTimeout($.proxy(function() {
            $item.addClass(this.classes.open);
        }, this), 250);
        
        if (Modernizr.mq('only all and (max-width: 1100px)')) {
            if ($item.index() % 2) {
                this.sideAccordionItems.eq($item.index() - 1).addClass('is-narrow');
            } else {
                this.sideAccordionItems.eq($item.index() + 1).addClass('is-narrow');
            }
        } else {
            this.sideAccordion.find('.' + this.config.wrapperClass).addClass('is-wide');
        }
    },
    closeAccordion: function($item) {
        $item.removeClass(this.classes.open);

        this.sideAccordion.removeClass('has-open');
        if (!Modernizr.mq('only all and (max-width: 1100px)')) {
            this.sideAccordion.find('.' + this.config.wrapperClass).removeClass('is-wide');
        }
    }
};

VTT.extend.Components(SideAccordion, 'SideAccordion');