/**
 * VTT 0.2
 * (c) 2014 Libéo
 * Dependencies : jQuery, lodash/underscore
 */

(function($, window, document, undefined) {

    /** Save a jQuery reference of the html */
    var $html = $('html');

    /** Save a jQuery reference of the body */
    var $body = $('body');

    /** Save a jQuery reference of the document */
    var $document = $(document);

    /** Application settings */
    var Settings = {};

    /** Classes used by the application */
    Settings.Classes = {
        active: 'is-active',
        open: 'is-open',
        close: 'is-closed',
        error: 'is-error',
        hover: 'is-hover',
        clicked: 'is-clicked',
        extern: 'is-external',
        current: 'is-current',
        fixed: 'is-fixed',
        mobile: 'is-mobile',
        zoom: 'l-zoomed',
        font: 'l-font',
    };

    /** Common functions */
    var Commons = {};

    /**
     * Add jQuery reverse function
     */
    Commons.setjqueryreverse = function() {
        if (typeof $ === 'function') $.fn.reverse = function() {
            return $(this.get().reverse());
        };
    };

    /*
     * Add jQuery focusable selector
     */
    Commons.setjqueryfocusable = function() {
        $.extend(jQuery.expr.pseudos, {
            focusable: function(el, index, selector) {
                return $(el).is('a, button, :input, [tabindex]');
            }
        });
    };

    /**
     * Rudimentary check to see if the user navigates with the keyboard
     */
    Commons.keynav = function() {
        $body.on('keydown', function(e) {
            var keyCode = (window.event) ? e.which : e.keyCode;
            if (!$body.attr('data-state')) {
                if (keyCode === 9 || keyCode === 13 || keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
                    $body.attr('data-state', 'keyboard');
                    $(document).trigger('keyboardnavigation');
                }
            }
            /*$body.on('mousedown', function(e) {
                if ($body.attr('data-state')) {
                    $body.removeAttr('data-state');
                }
            });*/
            $body.on('mousemove.LibeoDataState', function(e) {
                if ($body.attr('data-state')) {
                    $body.removeAttr('data-state');
                }
                $body.off('mousemove.LibeoDataState');
            });
        });
    };
    /**
     * Remove the focus outline in Firefox and IE when the user click on a link
     */

    Commons.betterfocus = function() {
        var classes = [Settings.Classes.clicked, Settings.Classes.hover],
            elements = ['a', 'button'].toString(),
            focusFunc = function(e) {
                var $this = $(this),
                    eClass = (e.type === 'click') ? classes[0] : classes[1];

                $($this.get(0).tagName.toLowerCase() + '.' + eClass).removeClass(eClass);
                if (e.type !== 'mouseleave') $this.addClass(eClass);
            };


        $body.on('click mouseenter mouseleave', elements, focusFunc);

        $document.on('keyboardnavigation', function() {
            for (var i = 0; i < classes.length; i++)
                if ($('.' + classes[i]).length) $('.' + classes[i]).removeClass(classes[i]);
            $body.off('click mouseenter mouseleave', elements, focusFunc);
        });
    };

    /**
     * Set a data-link attribute to external links
     */
    Commons.linkexternal = function() {
        var lang = $html.attr('lang');
        var warnings = {
            'fr': "Ce lien s'ouvrira dans une nouvelle fenêtre",
            'en': "This link will open in a new window"
        };

        /*var links = $('a[href^="http"]:not([href*="' + window.location.host + '"], .not-external)');

        links.each(function(){
            var content = $(this).text();
            var words = content.trim().split(" ");
            var lastWord = words.pop();
            var newContent = words.join(" ") + "<span class='lastWord'>" + lastWord + "</span>";
            $(this).html(newContent);
        });*/

        var svg = '<span class="nowrap">&#8288;<svg width="15" height="15" class="icon-lien_externe"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static_assets/svg/symbols.svg#lien_externe"></use></svg><span class="visuallyhidden">' + warnings[lang] + '</span></span>';

        //Appliquer l'icone sur tous les liens http ayant un location host différent

        $('a[href^="http"]:not([href*="' + window.location.host + '"], .not-external)').each(function(index){
            var link = $(this);

            if (link.find('.icon-lien_externe').length == 0) {

                link.attr('target', '_blank').addClass(Settings.Classes.extern).append(svg);

            }

        });

        //Appliquer l'icone sur tous les liens ayant le target="_blank"
        $('a[target="_blank"]:not([href^="tel"],[href^="http"], .not-external)').each(function(index){
            var link = $(this);

            if (link.find('.icon-lien_externe').length == 0) {

                link.addClass(Settings.Classes.extern).append(svg);

            }

        });

        //Appliquer l'icone sur tous les liens du blob
        $('a[target="_blank"][href*="' + window.location.host + '/blob"]:not([href^="tel"], .not-external)').each(function(index){
            var link = $(this);

            if (link.find('.icon-lien_externe').length == 0) {

                link.addClass(Settings.Classes.extern).append(svg);

            }

        });

        //Appliquer l'icone sur tous les liens saaqclic
        $('a[target="_blank"][href*="saaqclic.' + window.location.host + '"]:not([href^="tel"], .not-external), a[target="_blank"][href*="saaqclic-commercants.' + window.location.host + '"]:not([href^="tel"], .not-external)').each(function(index){
            var link = $(this);

            if (link.find('.icon-lien_externe').length == 0) {

                link.addClass(Settings.Classes.extern).append(svg);

            }

        });

        //Appliquer l'icone sur tous les liens de publications
        $('.is-external.publication').each(function(index){
            var link = $(this);

            if (link.find('.icon-lien_externe').length == 0) {

                link.addClass(Settings.Classes.extern).append(svg);

            }

        });

    };

    /**
     * Initial check of browser font size, attach the 'fontresize' event on the document
     */
    Commons.checkfont = function() {
        $document.on('fontresize', $.proxy(function() {
            this.managefont();
        }, this));
    };

    /**
     * Toggle 'isZoomed' and 'l-font' class on body tag when the user zoom or unzoom fonts. Works in Firefox only
     */
    Commons.managefont = function() {
        var bodyClasses = ($body.attr('class')) ? _.filter($body.attr('class').split(' '), function(x) {
                if (x.indexOf(Settings.Classes.font) === -1) return x;
            }) : '',
            fontsize = parseInt($body.css('font-size').replace('px', ''), 10);

        if (bodyClasses) $body.attr('class', '').addClass(bodyClasses.join(' '));

        if (fontsize > 16) {
            $body.addClass(Settings.Classes.zoom + ' ' + Settings.Classes.font + fontsize);
        } else {
            $body.removeClass(Settings.Classes.zoom);
        }
    };

    /**
     * Environment validation
     */
    Commons.setenvironment = function() {
        var Environment = {
            Android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
            },
            isIE10: function() {
                return !!navigator.userAgent.match(/MSIE.*10\./);
            },
            isIE11: function() {
                return !!navigator.userAgent.match(/Trident.*rv\:11\./);
            },
            isMobile: function() {
                return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
            }
        };

        if (Environment.isMobile()) {
            var mobile = Environment.isMobile().toString().toLowerCase();
            $body.attr('data-environment', 'mobile').addClass('is-' + mobile);
        } else {
            var ie10 = Environment.isIE10(),
                ie11 = Environment.isIE11();

            if (ie10) $html.addClass('ie10');
            if (ie11) $html.addClass('ie11');

            $body.attr('data-environment', 'desktop');
        }
    };

    /**
     * Avoid console errors in browsers that lack a console
     * https://github.com/h5bp/html5-boilerplate
     */
    Commons.safeconsole = function() {
        var method,
            noop = function() {},
            methods = [
                'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
                'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
                'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
                'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
            ],
            length = methods.length,
            console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];

            // Only stub undefined methods.
            if (!console[method]) {
                console[method] = noop;
            }
        }
    };


    /** Helper functions, uses data attribute in the DOM */
    var Helpers = {};

    /**
     * Set the same height to a collection of jQuery elements
     * @param {$} $element : parent of elements that will be set to the same height
     * @param {string} args : attributes, can be set to min, max or left empty
     */
    Helpers.equalheights = function($element, args) {
        var height = _.max($.makeArray($element.children().map(function() {
                return $(this).height();
            }))),
            attrs = args ? args + '-' : '';

        $element.children().css(attrs + 'height', height);
    };

    /** Set VTT namespace & expose to window */
    var VTT = window.VTT = {};

    VTT.Components = {};

    VTT.extend = {};

    /**
     * Extend VTT Components that can be used by the application
     * @param {object} obj : one or more component that will be added
     */
    VTT.extend.Components = function(obj, name) {
        if (typeof obj === 'function') VTT.Components[name] = obj;
    };

    /**
     * Extend VTT Commons functions with custom ones
     * @param {object} obj : one or more function that will be added
     */
    VTT.extend.Commons = function(obj) {
        if (typeof obj === 'object') _.each(_.functions(obj), function(func) {
            Commons[func] = obj[func];
        });
    };

    /**
     * Extend VTT Helpers functions with custom ones
     * @param {object} obj : one or more function that will be added
     */
    VTT.extend.Helpers = function(obj) {
        if (typeof obj === 'object') _.each(_.functions(obj), function(func) {
            Helpers[func] = obj[func];
        });
    };

    /**
     * Create Application
     * @param {string} appName : application name
     * @param {object} appSettings : application settings
     * @return {obj} app : reference to the global object
     */
    VTT.create = function(appName, appSettings) {
        var settings = appSettings || {},
            app = window[appName] = {};

        Settings = app.Settings = _.extend(Settings, settings);

        app.Commons = Commons;
        app.Helpers = Helpers;
        app.Components = {};
        app.Name = appName;

        /** Call all common functions */
        _.each(app.Commons, function(element, list) {
            app.Commons[list]();
        });

        /** Call all helper functions */
        _.each($('*[data-helper]'), function(element) {
            var helper = $(element).attr('data-helper'),
                func = helper.indexOf(':') !== -1 ? helper.substring(0, helper.indexOf(':')) : helper,
                args = helper.indexOf(':') !== -1 ? helper.substring(helper.indexOf(':') + 1, helper.length) : '';

            if (typeof Helpers[func] === 'function') {
                app.Helpers[func]($(element), args);
            } else {
                console.log('The helper "' + func + '" is undefined');
            }
        });

        /**
         * Set a component to the project global namespace
         * @param {string} namespace : application namespace
         * @param {object} obj : component
         * @param {$} $el : jQuery element for the component
         * @param {obj} config : component settings
         */
        app.setComponent = function(namespace, obj, $el, config) {
            var registered = app.Components;
            if ($el.length > 0) {
                if ($el.length === 1) {
                    if (typeof $.fn[obj] === 'function') {
                        registered[namespace] = $.fn[obj].call($el, config);
                    } else if (typeof VTT.Components[obj] === 'function') {
                        registered[namespace] = new VTT.Components[obj]($el, config || {}, Settings);
                    } else if (typeof window[obj] === 'function') {
                        registered[namespace] = new window[obj]($el, config || {}, Settings);
                    }
                } else {
                    _.each($el, function(element) {
                        var $element = $(element);

                        if (!registered[namespace]) registered[namespace] = [];

                        if (typeof $.fn[obj] === 'function') {
                            registered[namespace].push($.fn[obj].call($el, config));
                        } else if (typeof VTT.Components[obj] === 'function') {
                            registered[namespace].push(new VTT.Components[obj]($element, config || {}, Settings));
                        } else if (typeof window[obj] === 'function') {
                            registered[namespace].push(new window[obj]($element, config || {}, Settings));
                        }
                    });
                }
            }
        };

        return app;
    };

})(jQuery, window, document);
