function ServiceEnLigne(obj, config, app) {
    this.serviceEnLigne = obj;
    this.init();
}
ServiceEnLigne.prototype = {
    init: function() {
        var self = this;
        var currentHtmlAria = '<div class="visuallyhidden availabilitycheck" aria-atomic="true" aria-hidden="true" aria-live="polite"></div>';
        $('body').append(currentHtmlAria);
        setTimeout(function() {
            $('.availabilitycheck').text(SAAQ.Helpers.translate("check_availability"));
            self.serviceEnLigne.html('<p>'+SAAQ.Helpers.translate("check_availability")+'<br><img class="img-ajax-loader" src="/static_assets/img/ajax-loader.gif" alt=""></p>');
        }, 100);
        setTimeout(function() {
            $.ajax({
                url: self.serviceEnLigne.attr('data-url'),
                dataType: 'json',
                type: 'get',
                cache: false,
                complete: function(data, status, jqXHR) {
                    if (status == 'success') {
                        self.serviceEnLigne.html(data.responseJSON.render);
                        $('.availabilitycheck').text(SAAQ.Helpers.translate("service_available"));
                    } else {
                        self.serviceEnLigne.html('<p>'+SAAQ.Helpers.translate("service_not_available")+'</p>');
                        $('.availabilitycheck').text(SAAQ.Helpers.translate("service_not_available"));
                    }
                }
            });
        }, 3000);
    },
};

VTT.extend.Components(ServiceEnLigne, 'ServiceEnLigne');
