function DatePicker(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.datepicker = obj;

    this.config = $.extend({
        selectMonths: false,
        selectYears: false,
        birthday: false,
        editable: true
    }, {}, config);

    this.init();
}
DatePicker.prototype = {
    init: function() {
        this.$datepicker = this.datepicker.pickadate({
            format: 'yyyy-mm-dd',
            formatSubmit: 'yyyy-mm-dd',
            max: this.config.birthday ? true : false,
            firstDay: 0,
            editable: true,
            selectMonths: this.config.selectMonths,
            selectYears: this.config.selectYears ? (this.config.birthday ? 80 : 20) : false,
            onOpen: function() {
                var self = this;
                setTimeout( function() {

                    $(self.$root[0]).attr('aria-hidden', 'true');

                }, 0 );
            },
            onRender: function() {
                $(this.$root[0]).find(':focusable').attr('tabindex', '-1');
            }
        });
        this.picker = this.$datepicker.pickadate('picker');

        this.bindEvents();
    },
    bindEvents: function() {},
    createDatePickerTrigger: function() {
        var picker = this.picker;
        this.datepicker.after('<button class="datepicker-trigger"><span class="visuallyhidden">Afficher le calendrier</span></button>');
        this.datepicker.next('.datepicker-trigger').on('click', $.proxy(function(e) {
            this.picker.open();
            e.preventDefault();
        }, this));
    }
};

VTT.extend.Components(DatePicker, 'DatePicker');
