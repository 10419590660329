function Folder(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({}, {
        trigger: 'span',
        openAll: false,
        openFirst: false,
        contentWrap: false,
        uniqueFold: false,
        hasContextualLabel: false,
        foldClass: 'js-fold',
        buttonClass: 'js-fold-button',
        contentWrapClass: 'js-fold-content',
        openFoldClass: 'is-open',
        errorClass: 'js-fold-error',
        forcedOpenClass: 'js-default-open',
        contextualLabelClass: 'js-contextual-label',
        easing: 'linear',
        a11y: true,
        a11yAria: true,
        ariaTextOpen: ' Cliquer pour ouvrir',
        ariaTextClose: ' Cliquer pour fermer',
        animate: true,
        contextualOpen: "open_folder",
        contextualClose: "close_folder",
        durationOpen: 150,
        durationClose: 150,
        active: true,
        unbuttonizeTag: 'a',
        onInit: $.noop,
        beforeOpen: $.noop,
        beforeClose: $.noop,
        callbackOpen: $.noop,
        callbackClose: $.noop
    }, config);

    this.folder = obj;

    this.init();
  
    
}

Folder.prototype = {
    init: function() {
        this.config.onInit();

        this.lock = false;

        // Get each folds in the folder
        this.folds = this.folder.find('> .' + this.config.foldClass);

        // Get the folds trigger and transform the tags in a <button> tag

        if (this.config.active && this.folds.find('.' + this.config.buttonClass).length) {
            this.foldTrigger = this.folds.find('.' + this.config.buttonClass).prop('tagName').toLowerCase() === 'button' ? this.folds.find('.' + this.config.buttonClass) : this.folder.find('.' + this.makeButtons(this.folds.find('.' + this.config.buttonClass)));
        }

        // Get the content of each fold then hide it
        this.foldsContent = this.folds.find('> .' + this.config.contentWrapClass);

        // Initialize default functions
        this.manageDefaultOpenFolder();
        this.checkIfFoldContent();

        if (this.foldsContent.find('.' + this.config.errorClass).length) this.openFolderError();

        if (this.config.active) this.bindEvents();

        if(!this.config.a11yAria) this.makeLiveRegion();
    },
    activate: function() {
        if (!this.config.active) {
            this.triggerInitialH = this.folds.find('.' + this.config.buttonClass).show();
            this.foldTrigger = this.folder.find('.' + this.makeButtons(this.triggerInitialH));
            this.config.active = true;
            this.bindEvents();
        }
        this.folds.find('.' + this.config.buttonClass).each(function(i) {
            $(this).css('display', 'inline-block');
            if ($(this).attr('has-header') !== undefined && $(this).attr('has-header') !== false) {
                var currentHeader = $(this).attr('has-header');
                $(this).wrap('<' + currentHeader + ' class="has-button"></' + currentHeader + '>');
            }
        });
        this.closeAll();
    },
    deactivate: function(clb) {
        var self = this;

        this.folds.find('.' + this.config.buttonClass).each(function(i) {
            self.unbindEvents();
            self.config.active = false;
            if ($(this).attr('has-header') !== undefined && $(this).attr('has-header') !== false) {
                var tagName = $(this).parent()[0].nodeName.toLowerCase();

                if ($.inArray(tagName, ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']) > -1) {
                    $(this).unwrap();
                }
            }
            $(this).buttonize({
                unbuttonize: true,
                toTag: self.config.unbuttonizeTag
            });
        });

        if (typeof clb === 'function') clb();
    },
    makeButtons: function(el) {
        return el.buttonize({
            a11y: this.config.a11y,
            a11yAria: this.config.a11yAria
        }).attr('class').split(' ')[0];
    },
    makeLiveRegion: function() {
        var element = $('<div/>');
        element.attr({
            'aria-live': 'polite',
            'aria-hidden': 'true',
            'aria-atomic': 'true'
        }).addClass('visuallyhidden l-region');
        $('body').append(element);
    },
    // Check if a fold has content or if it's empty. If empty, replace the button by a span
    checkIfFoldContent: function() {
        var self = this;

        for (var i = 0; i < this.folds.length; i++) {
            var $this = $(this.folds[i]);

            $this.attr('data-id', 'folder-' + Math.floor(Math.random() * 1E16));

            if ($this.find('.' + this.config.contentWrapClass).length === 0) {
                var $thisButton = $this.find('button');

                if ($this.prop('tagName').toLowerCase() === 'nav') {

                    $thisButton.replaceWith('<h1 class="' + $thisButton.attr('class') + '">' + $thisButton.html() + '</h1>');

                    $this.replaceWith('<div class="' + _.difference($this.attr('class').split(' '), ['is-open']).join(' ') + '">' + $this.html() + '</div>');

                    $('h1.' + self.config.buttonClass).addClass('is-innactive').find('span').each(function() {
                        var $this = $(this);

                        if ($this.hasClass('navigation-button') || $this.hasClass('visuallyhidden')) {
                            $this.remove();
                        } else {
                            $this.addClass('wrapper');
                        }
                    });

                } else {
                    $thisButton.replaceWith('<span class="' + $thisButton.attr('class') + '">' + $thisButton.html() + '</span>');

                    $('span.' + self.config.buttonClass).addClass('is-innactive').find('span').remove();
                }
            }
        }
    },
    // Based on the plugin configuration, open desired fold (can be either first, all or a specific fold)
    manageDefaultOpenFolder: function() {
        // Open a specific fold if it has the forcedOpenClass
        for (var i = 0; i < this.folds.length; i++) {
            var $this = $(this.folds[i]);

            if ($this.hasClass(this.config.forcedOpenClass)) {

                this.openFold($this, $this.find('.' + this.config.contentWrapClass), false);
                this.forcedOpen = true;

                $this.removeClass(this.config.forcedOpenClass);
            }
        }

        // Open the first fold if config.open is set to "true"
        if (this.config.openFirst && !this.forcedOpen) this.openFirst();

        // Open the first fold if config.open is set to "true"
        if (this.config.openAll && !this.forcedOpen) this.openAll();
    },
    // Open first fold on first page load
    openFirst: function() {
        this.folds.first().addClass(this.config.openFoldClass).find(this.config.buttonClass + ' span.visuallyhidden').html(this.config.ariaTextClose);
        this.foldsContent.first().show();
    },
    changeAreaState: function() {
        this.folds.find('button span.visuallyhidden').html('Cliquer pour lancer la recherche');
    },
    // Open all folds on first page load
    openAll: function() {
        var self = this;

        for (var i = 0; i < this.folds.length; i++) {
            var $this = $(self.folds[i]),
                $thisContent = $this.find('.' + self.config.contentWrapClass);

            $this.addClass(self.config.openFoldClass).find(this.config.buttonClass + ' span.visuallyhidden').html(self.config.ariaTextClose);
            $thisContent.show();
        }
    },
    openFolderError: function() {
        var fold = this.foldsContent.find('.' + this.config.errorClass).closest('.' + this.config.foldClass);
        if (!fold.find('input:disabled').length) this.openFold(fold, fold.find('.' + this.config.contentWrapClass));
    },
    // Bind click event on the fold button
    bindEvents: function() {
        var self = this;

        self.folds.on('click', '.' + self.config.buttonClass, function(e) {
            if (self.lock) return false;

            var $this = $(this),
                foldContent = $this.closest("." + self.config.foldClass).find('.' + self.config.contentWrapClass);

            self.lock = true;

            if (!$this.closest('.' + self.config.foldClass).hasClass(self.config.openFoldClass)) {
                self.openFold($this, foldContent);
            } else {
                self.closeFold($this, foldContent);
            }

            e.preventDefault();
        });
    },
    unbindEvents: function() {
        this.folds.off('click', '.' + this.config.buttonClass);
    },
    openFold: function(el, foldContent, callBackFunc) {
        var self = this,
            callBack = callBackFunc || self.config.callbackOpen,
            openFolds,
            openFoldContent;
   
        if (this.config.hasContextualLabel) self.changeContextualLabelState(el, SAAQ.Helpers.translate(this.config.contextualClose));

        // Close other fold if uniqueFold config is true
        if (self.config.uniqueFold) {
            openFolds = self.folds.not(el.closest('.' + self.config.foldClass));
            openFoldContent = openFolds.find('.' + self.config.contentWrapClass);

            if (!self.folder.find('.is-open').length) {
                el.closest('.' + self.config.foldClass).addClass(self.config.openFoldClass);
                self.openAnimation(el.parent(), foldContent, callBack);
                return false;
            }

            el.closest('.' + self.config.foldClass).addClass(self.config.openFoldClass);

            self.closeFold(openFolds, openFoldContent, function() {
                var $trigger = self.folder.find('.is-open').find('.' + self.config.buttonClass);

                setTimeout(function() {
                    self.openAnimation($trigger.parent(), $trigger.closest('.' + self.config.foldClass).find('.' + self.config.contentWrapClass), callBack);
                }, self.config.durationClose);
            });
            if (self.config.a11y) self.changeFoldAriaState(el, self.config.ariaTextClose);
            return false;
        } 
        if (self.config.a11y) self.changeFoldAriaState(el, self.config.ariaTextClose);      
        el.closest('.' + self.config.foldClass).addClass(self.config.openFoldClass);
        self.openAnimation(el.parent(), foldContent, callBack);
    },
    closeFold: function(el, foldContent, callBackFunc) {
        var callBack = callBackFunc || this.config.callbackClose;

        el.closest('.' + this.config.foldClass).removeClass(this.config.openFoldClass);
        if (this.config.a11y) this.changeFoldAriaState(el, this.config.ariaTextOpen);
        if (this.config.hasContextualLabel) this.changeContextualLabelState(el, SAAQ.Helpers.translate(this.config.contextualOpen));

        this.closeAnimation(el.parent(), foldContent, callBack);
    },
    // Close all folds on first page load
    closeAll: function() {
        for (var i = 0; i < this.folds.length; i++) {
            var $this = $(this.folds[i]),
                $thisContent = $this.find('.' + this.config.contentWrapClass);
            $this.removeClass(this.config.openFoldClass).find(this.config.buttonClass + ' span.visuallyhidden').html(this.config.ariaTextOpen);
            $thisContent.hide();
        }

        this.config.callbackClose();
    },
    openAnimation: function(el, foldContent, callBack) {
        var self = this;


        if (el.prop('tagName').toLowerCase() === 'td' || !self.config.animate) {
            foldContent.show();
            self.lock = false;
        } else {
            foldContent.slideDown({
                duration: self.config.durationOpen,
                easing: self.config.easing,
                start: self.config.beforeOpen(),
                complete: function() {
                    callBack();
                    self.lock = false;
                }
            });
        }
    },
    closeAnimation: function($el, foldContent, callBack) {
        var self = this;

        if ($el.prop('tagName').toLowerCase() === 'td' || !self.config.animate) {
            foldContent.hide();
            self.lock = false;
        } else {
            foldContent.slideUp({
                duration: self.config.durationClose,
                easing: self.config.easing,
                start: self.config.beforeClose(),
                complete: function() {
                    callBack();
                    self.lock = false;
                }
            });
        }
    },
    // Change the text read by aria-live on state change
    changeFoldAriaState: function(el, message) {

        if (el.hasClass('cancelAnswer') || el.parent().hasClass('preferences-questions-modify')) return false;
        el.find('span.aria-holder').html(message);
        $('.l-region').html(message);
    },

    changeContextualLabelState: function(el, message) {
        el.find('> .' + this.config.contextualLabelClass).html(message);
    }
};

VTT.extend.Components(Folder, 'Folder');
