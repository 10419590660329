// ---------------------------- //
//             Form             //
// ---------------------------- //

function Form( obj, config, app) {
	this.app = app;
    this.form = $(obj);
    this.classes = this.app.Classes;
    this.disabled = false;

    this.config = $.extend({
        modifiers: {
            '!': 'not'
        }
    }, config);

    this.errors = [];
    this.init();

    this.form.data('formValidation', {disable: this.disable.bind(this)});
}

Form.prototype = {
    // Component initialization
    init: function() {
        this.bindEvents();
        is.setRegexp(/^\d{3}\s\d{3}-\d{4}$|^___ ___-____$|^$/, 'nanpPhone');
        is.setRegexp(/^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$|^___ ___$|^$/i, 'caPostalCode');
        is.setRegexp(/^(\w[-._\w]*\w@\w[-._\w]*\w\.\w{2,})$|^$/, 'email');
    },

    disable: function() {
        this.disabled = true;
    },

    // Bind events with actions
    // review
    bindEvents: function() {
        this.form.find('input:not(.datepicker-birthday, .datepicker, .datepicker-with-dropdown), textarea, select').on('focusout', $.proxy(function(e) {
            var input = this.form.find(e.currentTarget);
            var label = input.prev("label");

            label.find('.error-message-explanation, .error-message').remove();
            label.parent('div').removeClass('is-error');
            this.validateInput(input);

        }, this));

        this.form.find('input.datepicker-birthday, input.datepicker, input.datepicker-with-dropdown').on('change', $.proxy(function(e) {
            var input = this.form.find(e.currentTarget);
            var label = input.prev("label");

            label.find('.error-message-explanation, .error-message').remove();
            label.parent('div').removeClass('is-error');
            this.validateInput(input);

        }, this));

        this.form.find('.select-more-informations-options select').on('change', $.proxy(function(e) {
            var input = this.form.find(e.currentTarget);
            var value = input.val();
            var moreContent = input.parent('.select-more-informations-options').siblings('.select-more-informations-content');

            if (value) {
                moreContent.find('span[data-more]').hide();
                moreContent.find('span[data-more="'+value+'"]').show().length
                    ? moreContent.removeClass('select-more-informations-hidden')
                    : moreContent.addClass('select-more-informations-hidden');
            } else {
                moreContent.addClass('select-more-informations-hidden');
            }
        }, this));


        this.form.on('submit', $.proxy(function(e) {
            if(this.disabled){
                this.disabled = false;
                return;
            }
            this.resetErrors();
            this.validate();
            this.displayErrors();
            
            if (this.errors.length > 0) {
                e.preventDefault();
                $('div.errors p').focus();
            }
        }, this));
    },

    // Bind errors events with actions
    bindErrorsEvents: function() {
        // Scroll and focus input on error click
        this.form.find('.errors a').on('click', $.proxy(function(e) {
            var label = this.form.find(e.currentTarget.hash).parents('.field').find('label'),
                input = this.form.find(e.currentTarget.hash);
            input.focus();
            $(window).scrollTop(label.offset().top);
            e.preventDefault();
        }, this));
    },

    // Catch form submit and validate values
    validate: function() {
        var inputs = this.form.find('input, textarea, select, fieldset');
        this.errors = [];
        inputs.each($.proxy(function(index, input) {
            if (input.tagName.toLowerCase() === 'fieldset') {
                this.validateFieldset(input);
            } else {
                this.validateInput(input);
            }
        }, this));
    },

    // Validate current input
    validateInput: function(input) {

        var data = $(input).val();
        //var value = this.isNumber(data) ? parseInt(data) : data;
        // J'ai remplacé cette ligne car il faut qu'on conserve le 0 au début dans quelques cas
        // exemple: Numéro du dossier d'indemnisation = 01234567
        var value = data;
        var validationTerms = this.getValidationTerms(input);
        var empty = false;

        if (validationTerms) {
            _.each(validationTerms, $.proxy(function(validationTerm) {
                var valid = true,
                    term = this.getTerm(validationTerm),
                    modifier = this.getModifier(validationTerm),
                    compare = this.getCompare(input, term);
                if (!this.isNumber(value) && this.isNumber(compare)) {
                    value = value.length;
                }
                if (term === 'regex') {
                    var regex = new RegExp(compare);
                    valid = regex.test(data);
                } else if (term === 'minChar') {
                    valid = data.length >= compare;
                } else if (term === 'minCharOrEmpty') {
                    valid = data.length === 0 || data.length >= compare;
                } else if (term === 'maxChar') {
                    valid = data.length <= compare;
                } else if (term === 'requiredIfBNotEmpty') {
                    if (!this.requiredIfBNotEmpty(data, compare)) {
                        valid = false;
                    }
                } else if (term === 'requiredIfBNotChecked') {
                    if (!this.requiredIfBNotChecked(data, compare)) {
                        valid = false;
                    }
                } else if (term === 'oneOfMultiple') {
                    if(!this.oneFieldIsNotEmpty(compare)) {
                        valid = false;
                    }
                } else if (term === "minTime") {
                    valid = Math.floor(Date.now() / 1000) - value > compare;
                } else if (term === "maxTime") {
                    valid = Math.floor(Date.now() / 1000) - value < compare;
                } else if (modifier !== false) {
                    valid = is[modifier][term](value, compare) ? valid : false;
                } else {
                    valid = is[term](value, compare) ? valid : false;
                }
                if (!valid && term === 'empty') {
                    empty = true;
                    this.newError(input, this.getErrorMessage(input, term));
                }
                if (!valid && !empty) {
                    this.newError(input, this.getErrorMessage(input, term));
                }
            }, this));
        }
    },

    // Validate current fieldset (for radio button and checkboxes)
    validateFieldset: function(fieldset) {
        var inputs = $(fieldset).find('input'),
            validationTerms = this.getValidationTerms(fieldset),
            label = $(fieldset).find('legend'),
            checkedElements = [],
            empty = false;

        inputs.each(function(index, el) {
            if ($(el).is(':checked')) checkedElements.push(el);
        });

        if (validationTerms) {
            _.each(validationTerms, $.proxy(function(validationTerm) {
                var valid = true,
                    term = this.getTerm(validationTerm),
                    modifier = this.getModifier(validationTerm),
                    compare = this.getCompare(fieldset, term),
                    checked = this.getChecked(fieldset, term);
                if (term === 'empty') {
                    if (modifier !== false) {
                        valid = is[modifier][term](checkedElements, compare) ? valid : false;
                    } else {
                        valid = is[term](checkedElements, compare) ? valid : false;
                    }
                    if (!valid) {
                        empty = true;
                        this.newFieldsetError(fieldset, this.getErrorMessage(fieldset, term));
                    }
                } else if (term === 'oneOfMultipleIfBChecked') {
                    if (!this.isChecked(checked)) {
                        if(!this.oneFieldIsNotEmpty(compare)) {
                            this.newFieldsetError(fieldset, this.getErrorMessage(fieldset, term));
                        }
                    }
                } else if (term === 'oneOfMultiple') {
                    if(!this.oneFieldIsNotEmpty(compare)) {
                        this.newFieldsetError(fieldset, this.getErrorMessage(fieldset, term));
                    }
                } else {
                    if (modifier !== false) {
                        valid = is[modifier][term](checkedElements.length, compare) ? valid : false;
                    } else {
                        valid = is[term](checkedElements.length, compare) ? valid : false;
                    }
                    if (!valid && !empty) {
                        this.newFieldsetError(fieldset, this.getErrorMessage(fieldset, term));
                    }
                }
            }, this));
        }
    },

    requiredIfBNotEmpty : function(input, _compare) {
        var valid = false;
        if($('#' + _compare).val()) {
            if (input.length > 0) {
                valid = true;
            }
        } else {
            valid = true;
        }
        return valid;
    },

    requiredIfBNotChecked : function(input, _compare) {
        var valid = false;
        if(!this.isChecked(_compare)) {
            if (input.length > 0) {
                valid = true;
            }
        } else {
            valid = true;
        }
        return valid;
    },

    isChecked : function(input) {
        return $('#' + input).is(":checked");
    },

    oneFieldIsNotEmpty : function(_compare) {
        var valid = false;
        _compare.split(' ').forEach(function(fieldname) {
            if($('#' + fieldname).val()) {
                valid = true;
            }
        });
        return valid;
    },

    // Get validation terms
    getValidationTerms: function(input) {
        var data = $(input).data('validate');
        return data !== undefined ? data.trim().split(' ') : false;
    },

    // Normalize given term name
    getTerm: function(term) {
        return term.charAt(0) === '!' ? term.substring(1) : term;
    },

    // Get term modifier
    getModifier: function(term) {
        var modifier = this.config.modifiers[term.charAt(0)];
        return modifier !== undefined ? modifier : false;
    },

    // Get term comparison value
    getCompare: function(input, term) {
        var data = $(input).data('compare-' + term.toLowerCase());
        if (data === undefined) return null;
        if (String(data).charAt(0) == '#' && $(String(data)).length > 0) {
            return $(String(data)).val();
        }
        return this.isNumber(data) ? parseInt(data) : data;
    },
    // Get term checked value
    getChecked: function(input, term) {
        var data = $(input).data('checked-' + term.toLowerCase());
        if (data === undefined) return null;
        if (String(data).charAt(0) == '#' && $(String(data)).length > 0) {
            return $(String(data)).is(":checked");
        }
        return this.isNumber(data) ? parseInt(data) : data;
    },

    // Get term error message
    getErrorMessage: function(input, term) {
        var data = $(input).data('message-' + term.toLowerCase());
        return data !== undefined ? data : '';
    },

    // Reset form errors
    resetErrors: function() {
        this.form.find('.field').removeClass(this.classes.error);
        this.form.find('.error-message, .error-message-explanation').remove();
    },
    resetErrorsInput: function(input) {
        $(input).parent.find('.error-message, .error-message-explanation').remove();
    },

    // Throw new input error
    newError: function(input, message) {
        var id = $(input).attr('id'),
            label = this.form.find('label[for="' + id + '"]'),
            field = label.parents('.field').eq(0),
            markup = input.type === 'hidden' ? '<a href="#' + this.form.attr('id') + '">' + message + '</a>' : '<a href="#' + id + '">' + message + '</a>';

        field.addClass(this.classes.error);
        if (label.find('.error-message').length === 0) {
            label.prepend(' <span class="error-message">' + this.form.data('errors-prefix') + '</span>');
        }
        label.append(' <span class="error-message-explanation"><span>' + message + '</span></span>');
        this.errors.push(markup);
    },

    // Throw new fieldset error
    newFieldsetError: function(fieldset, message) {
        var $fieldset = $(fieldset),
            legend = $fieldset.find('legend'),
            error_id = legend.attr('id') !== undefined ? legend.attr('id') : $fieldset.attr('id'),
            markup = '<a href="#' + error_id + '">' + message + '</a>';

        $fieldset.addClass(this.classes.error);
        if (legend.find('.error-message').length === 0) {
            legend.prepend(' <span class="error-message">' + this.form.data('errors-prefix') + '</span>');
        }
        legend.append(' <span class="error-message-explanation" id="error-message-' + legend.attr('id') + '"><span>' + message + '</span></span>');
        this.errors.push(markup);
    },

    // Display errors summary
    displayErrors: function() {
        this.form.find('.errors').remove();
        if (this.errors.length > 0) {
            this.form.prepend('<div class="errors"></div>');
            this.form.errors = this.form.find('> .errors');
            this.form.errors.append('<p tabindex="-1">' + this.form.data('errors-message').replace('[n]', this.errors.length).split('[s]').join(this.errors.length > 1 ? 's' : '') + '</p>');
            this.form.errors.append('<ul></ul>');
            this.form.errorsList = this.form.find('> .errors > ul');
            _.each(this.errors, $.proxy(function(error) {
                this.form.errorsList.append('<li>' + error + '</li>');
            }, this));
            this.bindErrorsEvents();
        }
    },

    // Check if given value is a number
    isNumber: function(value) {
        if (!value) return false;
        return value.toString().match(/^\d+$/) && !isNaN(parseInt(value));
    }
};
VTT.extend.Components(Form, 'Form');

