function Config() {
    var urlType = Object.freeze({none:1, recursive:2});
    this.cookieCheckingURL = [
        new UrlCookieValidation("/services-en-ligne/citoyens/simuler-prelevements-bancaires/"),
        new UrlCookieValidation("/en/online-services/citizens/simulating-pre-authorized-debits/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/modifier-coordonnees-bancaires/"),
        new UrlCookieValidation("/en/online-services/citizens/changing-banking-information/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/demander-copie-dossier-conduite/"),
        new UrlCookieValidation("/en/online-services/citizens/requesting-copy-driving-record/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/verifier-validite-permis-conduire/"),
        new UrlCookieValidation("/en/online-services/citizens/checking-validity-drivers-licence/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/remiser-vehicule/"),
        new UrlCookieValidation("/en/online-services/citizens/storing-vehicle/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/deremiser-vehicule/"),
        new UrlCookieValidation("/en/online-services/citizens/licensing-stored-vehicle/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/mettre-vehicule-rancart/"),
        new UrlCookieValidation("/en/online-services/citizens/discarding-vehicle/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/verifier-droit-immatriculer-vehicule/"),
        new UrlCookieValidation("/en/online-services/citizens/checking-if-a-vehicle-can-be-registered/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/services-en-ligne-plaque-personnalisee/activer-plaque-immatriculation-personnalisee/"),
        new UrlCookieValidation("/en/online-services/citizens/personalized-licence-plate/activate-personalized-licence-plate/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/services-en-ligne-plaque-personnalisee/commander-plaque-immatriculation-personnalisee/"),
        new UrlCookieValidation("/en/online-services/citizens/personalized-licence-plate/order-personalized-licence-plate/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/envoi-documents/"),
        new UrlCookieValidation("/en/online-services/citizens/document-submission/"),
        new UrlCookieValidation("/services-en-ligne/citoyens/obtenir-certificat-exemption-pneus-hiver/"),
        new UrlCookieValidation("/en/online-services/citizens/obtaining-winter-tire-exemption-certificate/")
    ];
    
    this.allowedUrl = [
        new UrlCookieValidation("/services-en-ligne/citoyens/examen-conduite/", urlType.recursive),
        new UrlCookieValidation("/en/online-services/citizens/driving-test/", urlType.recursive),
        new UrlCookieValidation("/services-en-ligne/citoyens/faire-demande-indemnite/"),
        new UrlCookieValidation("/en/online-services/citizens/filing-claim-compensation/"),
        new UrlCookieValidation("/services-en-ligne/entreprises/"),
        new UrlCookieValidation("/services-en-ligne/intervenants-externes/envoi-documents/"),
        new UrlCookieValidation("/services-en-ligne/intervenants-externes/obtenir-numero-fournisseur-8-chiffres/")
    ]
}

Config.prototype.getUrlForCookieChecking = function () {
    return this.cookieCheckingURL;
};

Config.prototype.getAllowedURL = function() {
    return this.allowedUrl;
};

var config = new Config();