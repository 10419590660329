function ShoppingCart(obj, config, app) {
    this.app = app;
    this.classes = this.app.Classes;

    this.config = $.extend({
    	label_free_fr: "Gratuit",
    	label_free_en: "Free"
    }, config);

    this.shoppingCart = $(obj);
    this.subtotals = this.shoppingCart.find('.subtotal');
    this.totalPrice = this.shoppingCart.find('.total-price .number');

    this.init();
}

ShoppingCart.prototype = {
    init: function() {
        this.bindEvents();
        if(this.subtotals.length > 0) {
        	this.updateTotal();
        }
    },
    bindEvents: function() {
    	var self = this;
        self.shoppingCart.on('click', '.is-remove', function(e) {
        	e.preventDefault();
            $(this).parents('.tr').remove();

        	self.updateStoredSubtotals();
        	self.updateTotal();

        });
        if(this.subtotals.length > 0) {
        	$(document).on('onQuantityUpdate', function(event, trigger, quantity){
        		var rowToUpdate = trigger.parents('.tr');
	        	self.updateSubtotal(rowToUpdate, quantity);
	        });
        }
    },
    updateStoredSubtotals:function(){
    	this.subtotals = this.shoppingCart.find('.subtotal');
    },
    updateSubtotal: function(row, quantity) {
    	var itemPrice = (!isNaN(parseFloat(row.find('.price').data('value')))) ? parseFloat(row.find('.price').data('value')): row.find('.price').data('value');
    	var result;
    	if(itemPrice === 'free') {
    		if($('html').attr('lang') === 'fr') {
				result = this.config.label_free_fr;
    		} else {
    			result = this.config.label_free_en;
    		}
    	} else {
    		result = (itemPrice * quantity).toFixed(2).toString().replace('.', ',');
			if($('html').attr('lang') === 'fr') {
				result = result + ' $';
			} else {
				result = '$' + result;
			}
    	}
    	row.find('.subtotal').html(result);
    	this.updateStoredSubtotals();
    	this.updateTotal();
    },
    updateTotal: function() {
    	var total = 0;
    	_.forEach(this.subtotals, function(element){
    		var subtotal = parseFloat($(element).html().replace(',', '.').replace('$', ''));
    		if(isNaN(subtotal)) {
    			total = (total + 0);
    		} else {
    			total = (total + subtotal);
    		}
    		total = total.toFixed(2);
    	});

		total = total.toFixed(2).toString().replace('.', ',');
		if($('html').attr('lang') === 'fr') {
			total = total + ' $';
		} else {
			total = '$' + total;
		}
    	this.totalPrice.html(total);
    }
};

VTT.extend.Components(ShoppingCart, 'ShoppingCart');
