function Drive(obj, app) {
    this.app = app;
    this.classes = this.app.Classes;
    this.drive = $(obj);
    this.iconActive = this.drive.find('.i-drive-options a.is-selected');
    this.icons = this.drive.find('.i-drive-options a');
    this.optionSelected = this.drive.find('.i-drive-selected-option-mobile');
    this.buttonTrigger = this.drive.find('.js-idrive-trigger');
    this.buttonTriggerLabel = this.buttonTrigger.find('.js-idrive-button-label');
    this.fold = this.drive.find('.js-idrive-fold');
    this.isActive = false;
    this.labels = {
    	'en': ['Cancel', 'Change'],
    	'fr': ['Annuler', 'Modifier']
    }

    this.init();
}
Drive.prototype = {
    init: function() {
        var self = this;
        self.bindEvents();
    },
    bindEvents: function() {
        var self = this;
        $(window).on('resize', function() {
            if ($(this).innerWidth() <= 767 && self.isActive === false) {
            	self.isActive = true;
                self.activateObj();
            } else if ($(this).innerWidth() > 767 && self.isActive === true) {
                self.desactivateObj();
            }
        }).trigger('resize');

        self.drive.on('click', self.buttonTrigger, function() {
            self.changeButtonLabel();
        });
         self.icons.on('click', function() {
            self.hideVehiculeOptions(this);
        });
    },
    activateObj: function() {
        var self = this;
        self.resetButtonReferences();
        self.changeButtonLabel();
        self.optionSelected.css('display', 'block');
        if(!self.optionSelected.find('a.is-selected').length) {
            self.optionSelected.append((self.iconActive).clone());
        }
    },
    desactivateObj: function() {
        var self = this;
        self.optionSelected.css('display', 'none');
        self.isActive = false;
    },
    changeButtonLabel: function() {
    	if(this.fold.hasClass('is-open')) {
            this.buttonTriggerLabel.text('Annuler');
        } else {
            this.buttonTriggerLabel.text('Modifier');
        }
    },
    hideVehiculeOptions: function(vehicule) {
        var vehiculeSelected = $(vehicule);
        this.icons.removeClass('is-selected');
        this.optionSelected.children('a').remove();
        this.updateVehiculeSelected(vehiculeSelected);
    },
    updateVehiculeSelected: function(vehiculeSelected) {
        $(vehiculeSelected).addClass('is-selected');
        this.optionSelected.append(($(vehiculeSelected)).clone());
        this.buttonTrigger.click();
    },
    resetButtonReferences: function() {
    	this.buttonTrigger = this.drive.find('.js-idrive-trigger');
    	this.buttonTriggerLabel = this.buttonTrigger.find('.js-idrive-button-label');
    }
};

VTT.extend.Components(Drive, 'Drive');
