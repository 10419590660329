function UrlCookieValidation(url, type) {
    this.url = url;
    this.type = type || 1;
}

UrlCookieValidation.prototype.getUrl = function() {
    return this.url;
};

UrlCookieValidation.prototype.getType = function () {
  return this.type;
};

